/* eslint-disable no-param-reassign, global-require, func-names */
/* eslint-disable max-len */
import EventEmitter from 'events';
import assign from 'lodash/assign';
import findKey from 'lodash/findKey';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import uuidDefault from 'uuid';
import eventing from '../../helpers/eventing';
import createLogger from '../../helpers/log';
import IntervalRunner from '../interval_runner';
import convertAnvilErrorCode from '../../helpers/convertAnvilErrorCode';
import DefaultExceptionCodes from '../exception_codes';
import OTErrorClassDefault from '../ot_error_class';
import prependProxyToUrlIfNeeded from '../../helpers/proxyUrlHelper';
import eventNames from '../../helpers/eventNames';
import AnalyticsHelperDefault from '../../helpers/analytics';
import promisify from '../../helpers/promisify';
import testRemovingVideoCodecs from '../peer_connection/testRemovingVideoCodecs';
import validateIceConfigFactory from './validateIceConfig';
import hasE2eeCapabilityDefault from '../../helpers/hasE2eeCapability';
import hasIceRestartsCapabilityDefault from '../../helpers/hasIceRestartsCapability';
import sessionObjectsDefault from './objects';
import otErrorFactory from '../../helpers/otError';
import shouldUseSinglePeerConnection from '../../helpers/shouldUseSinglePeerConnection';
import ErrorsDefault from '../Errors';
import forceMuteErrors from './forceMuteErrors';
import { getProxyUrl } from '../proxyUrl';
import validateSecret from '../../sframe/validateSecret';
import { adaptIceServers as adaptIceServersDefault } from '../../RaptorSession/raptor/parseIceServers';
import APIKEYDefault from '../api_key';
import CapabilitiesDefault from '../capabilities';
import convertRumorErrorDefault from '../../helpers/convertRumorError';
import EventsFactory from '../events';
import StreamDefault from '../stream';
import OTHelpersDefault from '../../common-js-helpers/OTHelpers';
import PublisherFactory from '../publisher';
import RaptorSocketFactory from '../../RaptorSession/raptor/RaptorSocket';
import SessionDispatcherDefault from '../../RaptorSession/raptor/SessionDispatcher';
import sessionTagDefault from './tag';
import SubscriberFactory from '../subscriber';
import systemRequirementsFactoryDefault from '../system_requirements';
import getSessionInfoFactory from './getSessionInfo';
import SessionInfo from './SessionInfo';
import KeyStoreDefault from '../../sframe/keyStore';
import SFrameClientStoreFactory from '../../sframe/sframeClientStore';
import initPublisherFactory from '../publisher/init';
import staticConfigFactory from '../../helpers/StaticConfig';
import SinglePeerConnectionControllerDefault from '../peer_connection/singlePeerConnectionController';
import socketCloseCodesDefault from '../../RaptorSession/socketCloseCodes';
import CpuPressureMonitor from '../../helpers/cpuPressureMonitor';

const StaticConfigDefault = staticConfigFactory();

export default function SessionFactory(deps = {}) {
  const adaptIceServers = deps.adaptIceServers || adaptIceServersDefault;
  /** @type {AnalyticsHelper} */
  const AnalyticsHelper = deps.AnalyticsHelper || AnalyticsHelperDefault;
  const APIKEY = deps.APIKEY || APIKEYDefault;
  const Capabilities = deps.Capabilities || CapabilitiesDefault;
  const convertRumorError = deps.convertRumorError || convertRumorErrorDefault;
  const errors = deps.Errors || ErrorsDefault;
  const Events = deps.Events || EventsFactory();
  const Stream = deps.Stream || StreamDefault;
  const ExceptionCodes = deps.ExceptionCodes || DefaultExceptionCodes;
  const hasIceRestartsCapability = deps.hasIceRestartsCapability || hasIceRestartsCapabilityDefault;
  const hasE2eeCapability = deps.hasE2eeCapability || hasE2eeCapabilityDefault;
  const logging = deps.logging || createLogger('Session');
  const otError = deps.otError || otErrorFactory();
  const OTErrorClass = deps.OTErrorClass || OTErrorClassDefault;
  const OTHelpers = deps.OTHelpers || OTHelpersDefault;
  const PressureObserver = deps.PressureObserver || global?.PressureObserver;

  /** @type {typeof StaticConfigDefault} */
  const StaticConfig = deps.StaticConfig || StaticConfigDefault;

  /** @type {StaticConfigDefault} */
  const localStaticConfig = deps.staticConfig || StaticConfig.onlyLocal();

  const Publisher = deps.Publisher || PublisherFactory();
  const RaptorSocket = deps.RaptorSocket || RaptorSocketFactory();
  const SessionDispatcher = deps.SessionDispatcher || SessionDispatcherDefault;
  const sessionObjects = deps.sessionObjects || sessionObjectsDefault;
  const sessionTag = deps.sessionTag || sessionTagDefault;
  const socketCloseCodes = deps.socketCloseCodes || socketCloseCodesDefault;
  const Subscriber = deps.Subscriber || SubscriberFactory();
  const systemRequirementsFactory =
    deps.systemRequirementsFactory || systemRequirementsFactoryDefault;
  const uuid = deps.uuid || uuidDefault;
  const validateIceConfig = validateIceConfigFactory({ otError });
  const windowMock = deps.global || global;
  const getSessionInfo = deps.getSessionInfo || getSessionInfoFactory();
  const KeyStore = deps.KeyStore || KeyStoreDefault;
  const SFrameClientStore = deps.SFrameClientStore || SFrameClientStoreFactory();
  const SinglePeerConnectionController =
    deps.SinglePeerConnectionController ||
    SinglePeerConnectionControllerDefault;

  const initPublisher = deps.initPublisher || initPublisherFactory({
    Publisher,
  });

  /**
   * The Session object returned by the <code>OT.initSession()</code> method provides access to
   * much of the Vonage Video API functionality.
   *
   * @class Session
   * @augments EventDispatcher
   *
   * @property {Capabilities} capabilities A {@link Capabilities} object that includes information
   * about the capabilities of the client. All properties of the <code>capabilities</code> object
   * are undefined until you have connected to a session and the completion handler for the
   * <code>Session.connect()</code> method has been called without error.
   * @property {Connection} connection The {@link Connection} object for this session. The
   * connection property is only available once the completion handler for the
   * <code>Session.connect()</code> method has been called successfully. See the
   * <a href="#connect">Session.connect()</a> method and the {@link Connection} class.
   * @property {String} sessionId The session ID for this session. You pass this value into the
   * <code>OT.initSession()</code> method when you create the Session object. (Note: a Session
   * object is not connected to the Vonage Video API server until you call the connect() method of the
   * object and its completion handler is called without error. See the
   * <a href="OT.html#initSession">OT.initSession()</a> and
   * the <a href="#connect">Session.connect()</a>
   * methods.) For more information on sessions and session IDs, see
   * <a href="https://tokbox.com/developer/guides/create-session/">Session creation</a>.
   */

  const Session = function (apiKey, sessionId, { iceConfig, connectionEventsSuppressed, ipWhitelist = false, encryptionSecret: initialEncryptionSecret,
    singlePeerConnection = false, _prioritizeVP9 = false } = {}) {
    const proxyUrl = getProxyUrl();

    /** @type AnalyticsHelperDefault */
    const analytics = new AnalyticsHelper();

    const getStream = stream => (typeof stream === 'string' ? this.streams.get(stream) || { id: stream } : stream);

    eventing(this);
    this._tag = sessionTag;

    // Check that the client meets the minimum requirements, if they don't the upgrade
    // flow will be triggered.
    const systemRequirements = systemRequirementsFactory();
    if (!systemRequirements.check()) {
      systemRequirements.upgrade();
    }

    if (sessionId == null) {
      sessionId = apiKey;
      apiKey = null;
    }

    validateIceConfig(iceConfig);

    this.id = sessionId;
    this.sessionId = sessionId;

    this.keyStore = new KeyStore();
    this.sFrameClientStore = new SFrameClientStore();

    let _socket;

    /** @type IntervalRunner | undefined */
    let _connectivityAttemptPinger;

    let _token;
    let _p2p;
    let _messagingServer;
    let _attemptStartTime;
    let _configurationAttemptStartTime;
    let _iceServerDetails;
    let _isSocketReconnecting = false;
    let _apiKey = apiKey;
    const _session = this;
    let _sessionId = sessionId;
    let _connectionId = uuid();
    let _logging = logging;
    let _muteOnEntry = false;
    const _subscribersQueue = [];
    let _subscribersQueueInterval = null;
    let _e2eeSecretSet = false;
    let _useSinglePeerConnection;
    let encryptionSecret = initialEncryptionSecret;

    let disconnectComponents;
    let reset;
    let destroyPublishers;
    let destroySubscribers;

    const setState = OTHelpers.statable(this, [
      'disconnected', 'connecting', 'connected', 'disconnecting',
    ], 'disconnected');

    this.connection = null;
    this.connections = new OTHelpers.Collection();
    this.streams = new OTHelpers.Collection();
    this.archives = new OTHelpers.Collection();

    let _singlePeerConnectionController;
    let cpuPressureMonitor;

    if (PressureObserver) {
      cpuPressureMonitor = new CpuPressureMonitor();
      cpuPressureMonitor.initMonitoring(PressureObserver);
      cpuPressureMonitor.startMonitoring();
      cpuPressureMonitor.on('pressureMonitorEvent', (newState) => {
        this.dispatchEvent(new Events.CpuPerformanceChangedEvent(newState));
      });
    }

    //--------------------------------------
    //  MESSAGE HANDLERS
    //--------------------------------------

    /*
     * The sessionConnectFailed event handler
     * @param {Error}
     */
    const sessionConnectFailed = function (error) {
      setState('disconnected');

      if (!error.code) {
        error.code = ExceptionCodes.CONNECT_FAILED;
      }

      _logging.error(`${error.name || 'Unknown Error'}: ${error.message}`);

      OTErrorClass.handleJsException({
        error,
        target: this,
        analytics,
      });

      this.trigger('sessionConnectFailed', error);
    };

    const sessionDisconnectedHandler = function (event) {
      _isSocketReconnecting = false;
      const reason = event.reason;
      this.logEvent('Connect', 'Disconnected', { reason: event.reason });

      const publicEvent = new Events.SessionDisconnectEvent(
        'sessionDisconnected',
        reason.replace('networkTimedout', 'networkDisconnected')
      );

      if (this.isConnected()) {
        this.disconnect();
      }
      reset();
      disconnectComponents.call(this, reason);

      setTimeout(() => {
        this.dispatchEvent(publicEvent);

        // Although part of the defaultAction for sessionDisconnected we have
        // chosen to still destroy Publishers within the session as there is
        // another mechanism to stop a Publisher from being destroyed.

        // Publishers use preventDefault on the Publisher streamDestroyed event
        destroyPublishers.call(this, publicEvent.reason);

        if (!publicEvent.isDefaultPrevented()) {
          destroySubscribers.call(this, publicEvent.reason);
        }
      });
    };

    const connectionCreatedHandler = function (connection) {
      // With connectionEventsSuppressed, connections objects are still added internally, but they
      // don't come from rumor messages, and can't have corresponding delete events, so we don't
      // emit created events.
      if (connectionEventsSuppressed) {
        return;
      }

      // We don't broadcast events for the symphony connection
      if (connection.id.match(/^symphony\./)) { return; }

      this.dispatchEvent(new Events.ConnectionEvent(
        eventNames.CONNECTION_CREATED,
        connection
      ));
    };

    const connectionDestroyedHandler = function (connection, reason) {
      // We don't broadcast events for the symphony connection
      if (connection.id.match(/^symphony\./)) { return; }

      // Don't delete the connection if it's ours. This only happens when
      // we're about to receive a session disconnected and session disconnected
      // will also clean up our connection.
      if (_socket && connection.id === _socket.id()) { return; }

      this.dispatchEvent(
        new Events.ConnectionEvent(
          eventNames.CONNECTION_DESTROYED,
          connection,
          reason
        )
      );
    };

    const streamCreatedHandler = function (stream) {
      if (
        stream &&
        stream.connection &&
        (!this.connection || stream.connection.id !== this.connection.id)
      ) {
        this.dispatchEvent(new Events.StreamEvent(
          eventNames.STREAM_CREATED,
          stream,
          null,
          false
        ));
      }
    };

    const streamPropertyModifiedHandler = function (event) {
      const stream = event.target;
      const propertyName = event.changedProperty;
      let newValue = event.newValue;

      if (propertyName === 'videoDisableWarning' || propertyName === 'audioDisableWarning') {
        return; // These are not public properties, skip top level event for them.
      }

      if (propertyName === 'videoDimensions') {
        newValue = { width: newValue.width, height: newValue.height };
      }

      this.dispatchEvent(new Events.StreamPropertyChangedEvent(
        eventNames.STREAM_PROPERTY_CHANGED,
        stream,
        propertyName,
        event.oldValue,
        newValue
      ));
    };

    const streamDestroyedHandler = function (stream, reason = 'clientDisconnected') {
      const event = new Events.StreamEvent('streamDestroyed', stream, reason, true);
      const disconnectAndDestroySubscribers = () => {
        // If we are subscribed to any of the streams we should unsubscribe
        sessionObjects.subscribers
          .where({ streamId: stream.id })
          .filter(subscriber => subscriber.session.id === this.id && subscriber.stream)
          .forEach((subscriber) => {
            subscriber._disconnect({ reason });

            if (!event.isDefaultPrevented()) {
              subscriber._destroy({ reason, noStateTransition: true });
            }
          });
      };

      // if the stream is one of ours we delegate handling to the publisher itself.
      if (stream.connection.id === this.connection.id) {
        sessionObjects.publishers.where({ streamId: stream.id }).forEach(function (publisher) {
          publisher._.unpublishStreamFromSession(stream, this, reason);
        }, this);
      } else {
        // The streamDestroyed event is only dispatched by the session when the stream is not one of ours.
        // In the case the stream is ours, the streamDestroyed is dispatched by the publisher.
        this.dispatchEvent(event);
      }

      disconnectAndDestroySubscribers();

      // @TODO Add a else with a one time warning that this no longer cleans up the publisher
    };

    const archiveCreatedHandler = function (archive) {
      this.dispatchEvent(new Events.ArchiveEvent('archiveStarted', archive));
    };

    const archiveDestroyedHandler = function (archive) {
      this.dispatchEvent(new Events.ArchiveEvent('archiveDestroyed', archive));
    };

    const archiveUpdatedHandler = function (event) {
      const archive = event.target;
      const propertyName = event.changedProperty;
      const newValue = event.newValue;

      if (propertyName === 'status' && newValue === 'stopped') {
        this.dispatchEvent(new Events.ArchiveEvent('archiveStopped', archive));
      } else {
        this.dispatchEvent(new Events.ArchiveEvent('archiveUpdated', archive));
      }
    };

    // OPENTOK-42152: When a GSM call is ended, the subscribers might have an issue
    // in which a black frame is displayed instead of video.
    // A workaround is to pause and play the subscriber's video element.
    const gsmCallEndedHandler = () =>
      sessionObjects.subscribers.forEach(subscriber => subscriber._.pauseAndPlayVideoElement());

    const init = function () {
      _session.token = null;
      _token = null;
      setState('disconnected');
      _socket = null;
      if (_connectivityAttemptPinger) {
        _connectivityAttemptPinger.stop();
        _connectivityAttemptPinger = null;
      }
      _session.connection = null;
      _session.capabilities = new Capabilities([], { hasE2eeCapability });
      _session.connections.destroy();
      _session.streams.destroy();
      _session.archives.destroy();
    };

    // Put ourselves into a pristine state
    reset = function () {
      // reset connection id now so that following calls to testNetwork and connect will share
      // the same new session id. We need to reset here because testNetwork might be call after
      // and it is always called before the session is connected
      // on initial connection we don't reset
      _connectionId = uuid();
      init();
    };

    disconnectComponents = function (reason) {
      sessionObjects.publishers.where({ session: this }).forEach((publisher) => {
        publisher.disconnect(reason);
      });

      sessionObjects.subscribers.where({ session: this }).forEach((subscriber) => {
        subscriber._disconnect();
      });
    };

    destroyPublishers = function (reason) {
      sessionObjects.publishers.where({ session: this }).forEach((publisher) => {
        publisher._.streamDestroyed(reason);
      });
    };

    destroySubscribers = function (reason) {
      sessionObjects.subscribers.where({ session: this }).forEach((subscriber) => {
        subscriber._destroy({ reason });
      });
    };

    const connectMessenger = function () {
      _logging.debug('OT.Session: connecting to Raptor');

      const messagingUrl = prependProxyToUrlIfNeeded(this.sessionInfo.messagingURL, proxyUrl);
      // capabilities supported by default.
      const capabilities = ['forceMute'];
      // Add Adaptive Media Routing capability if enabled for the session.
      const { isAdaptiveEnabled } = this.sessionInfo;
      if (isAdaptiveEnabled) {
        capabilities.push('amr');
      }
      if (_useSinglePeerConnection) {
        capabilities.push('spc');
      }

      _socket = new RaptorSocket({
        connectionId: _connectionId,
        sessionId,
        messagingSocketUrl: messagingUrl,
        symphonyUrl: this.sessionInfo.symphonyAddress,
        dispatcher: SessionDispatcher(this, { connectionEventsSuppressed }),
        analytics,
        requestedCapabilities: capabilities,
      });

      /**
       * Maps an error from RaptorSocket.connect to its corresponding name
       * @param {string} reason - Failure reason
       * @param {number} code - Error code
       * @return {string|undefined} Error name
       */
      function getErrorNameFromCode(reason, code) {
        let name;
        switch (reason) {
          case 'WebSocketConnection':
            name = findKey(socketCloseCodes.codes, x => x === code);
            if (name) {
              return errors[`SOCKET_${name}`];
            }
            break;
          case 'ConnectToSession':
          case 'GetSessionState':
            switch (code) {
              case ExceptionCodes.CONNECT_FAILED:
                return errors.CONNECT_FAILED;
              case ExceptionCodes.UNEXPECTED_SERVER_RESPONSE:
                return errors.UNEXPECTED_SERVER_RESPONSE;
              case ExceptionCodes.CONNECTION_LIMIT_EXCEEDED:
                return errors.CONNECTION_LIMIT_EXCEEDED;
              default:
                break;
            }
            break;
          default:
            break;
        }
        return undefined;
      }

      _socket.connect(_token, this.sessionInfo, { connectionEventsSuppressed }, (error, sessionState) => {
        if (error) {
          const payload = {};
          let options;

          if (error.reason === 'ConnectToSession' || error.reason === 'GetSessionState') {
            const converted = convertRumorError(error);
            assign(payload, {
              originalMessage: error.message,
              originalCode: error.code,
            });
            error.code = converted.code;
            error.message = converted.message;
          }

          if (error.code || error.message || error.reason) {
            options = {
              failureCode: error.code,
              failureMessage: error.message,
              failureReason: error.reason,
              socketId: _socket.socketId,
            };
          }
          _socket = null;
          this.logConnectivityEvent('Failure', payload, options);

          const errorName = getErrorNameFromCode(error.reason, error.code);
          if (errorName) {
            error = otError(errorName, new Error(error.message), error.code);
          }

          sessionConnectFailed.call(this, error);
          return;
        }

        _logging.debug('OT.Session: Received session state from Raptor', sessionState);
        this.connection = this.connections.get(_socket.id());
        if (this.connection) {
          this.capabilities = this.connection.permissions;
        }

        setState('connected');

        this.logConnectivityEvent('Success', { connectionId: this.connection.id });

        // Listen for our own connection's destroyed event so we know when we've been disconnected.
        this.connection.on('destroyed', sessionDisconnectedHandler, this);

        this.dispatchEvent(new Events.SessionConnectEvent(eventNames.SESSION_CONNECTED));
        // Listen for connection updates
        this.connections.on({
          add: connectionCreatedHandler,
          remove: connectionDestroyedHandler,
        }, this);

        // Listen for stream updates
        this.streams.on({
          add: streamCreatedHandler,
          remove: streamDestroyedHandler,
          update: streamPropertyModifiedHandler,
        }, this);

        this.archives.on({
          add: archiveCreatedHandler,
          remove: archiveDestroyedHandler,
          update: archiveUpdatedHandler,
        }, this);

        this.connections._triggerAddEvents(); // { id: this.connection.id }
        this.streams._triggerAddEvents(); // { id: this.stream.id }
        this.archives._triggerAddEvents();

        // Listen for the gsmCallEnded event triggered by the publisher when a GSM call ends.
        const isBuggediOS = OTHelpers.env.isiOS && OTHelpers.env.iOSVersion >= 13.3;
        if (isBuggediOS) {
          this.on('gsmCallEnded', gsmCallEndedHandler);
        }
      });
    };

    // Check whether we have permissions to perform the action.
    const permittedTo = action => this.capabilities.permittedTo(action);

    const dispatchOTError = (error, completionHandler) => {
      logging.error(`${error.name}: ${error.message}`);

      if (typeof completionHandler === 'function') {
        completionHandler(error);
      }

      OTErrorClass.handleJsException({
        error,
        target: this,
        analytics,
      });
    };

    const dispatchMuteError = (err) => {
      const error = otError(err.code, new Error(err.message), err.exceptionCode);
      dispatchOTError(error);
      return error;
    };

    const checkMuteCapabilities = () => {
      if (this.isNot('connected')) {
        return dispatchMuteError(forceMuteErrors.NOT_CONNECTED);
      }
      if (!permittedTo('forceMute')) {
        // if this throws an error the handleJsException won't occur
        return dispatchMuteError(forceMuteErrors.PERMISSION_DENIED);
      }
      return null;
    };

    const handleMuteServerError = (err) => {
      if (err.code === '404') {
        return dispatchMuteError(forceMuteErrors.NOT_FOUND);
      } else if (err.code === '403') {
        return dispatchMuteError(forceMuteErrors.PERMISSION_DENIED);
      }
      return dispatchMuteError(forceMuteErrors.UNEXPECTED_SERVER_RESPONSE);
    };

    this.reportIssue = ({ id }) => promisify(::analytics.logEvent)(
      {
        action: 'ReportIssue',
        variation: 'Event',
        connectionId: _connectionId,
        payload: {
          reportIssueId: id,
        },
      },
      null
    );

    this.logEvent = function (action, variation, payload, options) {
      let event = {
        action,
        variation,
        payload,
        sessionId: _sessionId,
        messagingServer: _messagingServer,
        p2p: _p2p,
        partnerId: _apiKey,
        connectionId: _connectionId,
        singlePeerConnection: _useSinglePeerConnection,
      };

      if (options) { event = assign(options, event); }
      analytics.logEvent(event);
    };

    this.logConfigurationFileEvent = function (variation, payload = null, options = {}) {
      if (variation === 'Attempt') {
        _configurationAttemptStartTime = new Date().getTime();
      } else if (variation === 'Failure' || variation === 'Success') {
        const attemptDuration = new Date().getTime() - _configurationAttemptStartTime;
        assign(options, { attemptDuration });
      }
      if (proxyUrl) {
        options.proxyUrl = proxyUrl;
      }

      this.logEvent('ConfigurationFile', variation, payload, {
        ...options,
      });
    };

    this.logConnectivityEvent = function (variation, payload = null, options = {}) {
      if (proxyUrl) {
        options.proxyUrl = proxyUrl;
      }
      if (variation === 'Attempt') {
        if (_connectivityAttemptPinger) {
          _connectivityAttemptPinger.stop();
          logging.error('_connectivityAttemptPinger should have been cleaned up');
        }

        _attemptStartTime = new Date().getTime();

        _connectivityAttemptPinger = new IntervalRunner(
          () => {
            this.logEvent('Connect', 'Attempting', payload, {
              ...options,
            });
          },
          1 / 5,
          6
        );
        _connectivityAttemptPinger.start();
      }

      if (variation === 'Failure' || variation === 'Success' || variation === 'Cancel') {
        const logConnect = (_iceConfig) => {
          if (_connectivityAttemptPinger) {
            _connectivityAttemptPinger.stop();
            _connectivityAttemptPinger = undefined;
          }
          this.logEvent('Connect', variation, payload, {
            ...options,
            attemptDuration: new Date().getTime() - _attemptStartTime,
            iceConfig: _iceConfig,
            ipWhitelist,
          });
        };
        if (variation === 'Success') {
          this._.getIceConfig().then((config) => {
            const _iceConfig = {
              includeServers: (iceConfig && iceConfig.includeServers) || 'all',
              transportPolicy: config.transportPolicy,
            };
            _iceConfig.servers = config.servers ? config.servers.map(server => ({
              url: server.urls,
            })) : [];
            logConnect(_iceConfig);
          });
        } else {
          logConnect();
        }
      } else {
        this.logEvent('Connect', variation, payload, options);
      }
    };

    /**
    * Connects to a Vonage Video API session.
    * <p>
    *  Upon a successful connection, the completion handler (the second parameter of the method) is
    *  invoked without an error object passed in. (If there is an error connecting, the completion
    *  handler is invoked with an error object.) Make sure that you have successfully connected to the
    *  session before calling other methods of the Session object.
    * </p>
    *  <p>
    *    The Session object dispatches a <code>connectionCreated</code> event when any client
    *    (including your own) connects to the session.
    *  </p>
    *
    *  <h5>
    *  Example
    *  </h5>
    *  <p>
    *  The following code initializes a session and sets up an event listener for when the session
    *  connects:
    *  </p>
    *  <pre>
    *  var apiKey = ""; // Replace with your API key. See https://tokbox.com/account
    *  var sessionID = ""; // Replace with your own session ID.
    *                      // See https://tokbox.com/developer/guides/create-session/.
    *  var token = ""; // Replace with a generated token.
    *                  // See https://tokbox.com/developer/guides/create-token/.
    *
    *  var session = OT.initSession(apiKey, sessionID);
    *  session.connect(token, function(error) {
    *    if (error) {
    *      console.log(error.message);
    *    } else {
    *      // You have connected to the session. You could publish a stream now.
    *    }
    *  });
    *  </pre>
    *  <p>
    *
    *  <h5>
    *  Events dispatched:
    *  </h5>
    *
    *  <p>
    *    <code>exception</code> (<a href="ExceptionEvent.html">ExceptionEvent</a>) &#151; Dispatched
    *    by the OT class locally in the event of an error.
    *  </p>
    *  <p>
    *    <code>connectionCreated</code> (<a href="ConnectionEvent.html">ConnectionEvent</a>) &#151;
    *      Dispatched by the Session object on all clients connected to the session.
    *  </p>
    *  <p>
    *    <code>sessionConnected</code> (<a href="SessionConnectEvent.html">SessionConnectEvent</a>)
    *      &#151; Dispatched locally by the Session object when the connection is established. However,
    *      you can pass a completion handler function in as the second parameter of the
    *      <code>connect()</code> and use this function instead of a listener for the
    *      <code>sessionConnected</code> event.
    *  </p>
    *
    * @param {String} token The session token. You generate a session token using our
    * <a href="https://tokbox.com/developer/sdks/server/">server-side libraries</a> or at your
    * <a href="https://tokbox.com/account">Vonage Video API account</a> page. For more information, see
    * <a href="https://tokbox.com/developer/guides/create-token/">Connection token creation</a>.
    *
    * @param {Function} completionHandler (Optional) A function to be called when the call to the
    * <code>connect()</code> method succeeds or fails. This function takes one parameter &mdash;
    * <code>error</code> (see the <a href="Error.html">Error</a> object).
    * On success, the <code>completionHandler</code> function is not passed any
    * arguments. On error, the function is passed an <code>error</code> object parameter
    * (see the <a href="Error.html">Error</a> object). The
    * <code>error</code> object has two properties: <code>code</code> (an integer) and
    * <code>message</code> (a string), which identify the cause of the failure. The following
    * code adds a <code>completionHandler</code> when calling the <code>connect()</code> method:
    * <pre>
    * session.connect(token, function (error) {
    *   if (error) {
    *       console.log(error.message);
    *   } else {
    *     console.log("Connected to session.");
    *   }
    * });
    * </pre>
    * <p>
    * Note that upon connecting to the session, the Session object dispatches a
    * <code>sessionConnected</code> event in addition to calling the <code>completionHandler</code>.
    * The SessionConnectEvent object, which defines the <code>sessionConnected</code> event,
    * includes <code>connections</code> and <code>streams</code> properties, which
    * list the connections and streams in the session when you connect.
    * </p>
    *
    * @see SessionConnectEvent
    * @method #connect
    * @memberOf Session
  */
    this.connect = (...args) => {
      let token;
      if (args.length > 1 &&
        (typeof args[0] === 'string' || typeof args[0] === 'number') &&
        typeof args[1] === 'string') {
        if (apiKey == null) { _apiKey = args[0].toString(); }
        token = args[1];
      } else {
        token = args[0];
      }

      // The completion handler is always the last argument.
      const completionHandler = args[args.length - 1];

      if (this.is('connecting', 'connected')) {
        _logging.warn(`OT.Session: Cannot connect, the session is already ${this.currentState}`);
        return this;
      }

      if (this.is('disconnecting')) {
        _logging.warn('OT.Session: trying to connect while the session is not done disconnecting.');
        _connectionId = uuid();
      }

      // On disconnects, GSI fields may be unintentionally cached and logged so we instantiate a new instance.
      analytics.sessionInfo = new SessionInfo();

      init();
      setState('connecting');
      const currentConnectionId = _connectionId;

      function checkInterrupted() {
        const interrupted = _connectionId !== currentConnectionId;

        if (interrupted) {
          logging.debug('Connection was interrupted');
        }

        return interrupted;
      }

      this.token = !isFunction(token) && token;
      _token = !isFunction(token) && token;

      if (completionHandler && isFunction(completionHandler)) {
        let cleanup;
        const onCompleteSuccess = (...cbArgs) => {
          cleanup();
          completionHandler(undefined, ...cbArgs);
        };
        const onCompleteFailure = (...cbArgs) => {
          cleanup();
          completionHandler(...cbArgs);
        };
        cleanup = () => {
          this.off('sessionConnected', onCompleteSuccess);
          this.off('sessionConnectFailed', onCompleteFailure);
        };
        this.once('sessionConnected', onCompleteSuccess);
        this.once('sessionConnectFailed', onCompleteFailure);
      }

      if (_apiKey == null || isFunction(_apiKey)) {
        setTimeout(sessionConnectFailed.bind(this, otError(
          errors.AUTHENTICATION_ERROR,
          new Error('API Key is undefined. You must pass an API Key to initSession.'),
          ExceptionCodes.AUTHENTICATION_ERROR
        )));

        return this;
      }

      if (!_sessionId || isObject(_sessionId) || Array.isArray(_sessionId)) {
        let errorMsg;
        if (!_sessionId) {
          errorMsg = 'SessionID is undefined. You must pass a sessionID to initSession.';
        } else {
          errorMsg = 'SessionID is not a string. You must use string as the session ID passed into ' +
            'OT.initSession().';
          _sessionId = _sessionId.toString();
        }
        setTimeout(sessionConnectFailed.bind(this, otError(
          errors.INVALID_SESSION_ID,
          new Error(errorMsg),
          ExceptionCodes.INVALID_SESSION_ID
        )));

        this.logConnectivityEvent('Attempt');

        this.logConnectivityEvent('Failure', null, {
          failureReason: 'ConnectToSession',
          failureCode: ExceptionCodes.INVALID_SESSION_ID,
          failureMessage: errorMsg,
        });
        return this;
      }

      this.apiKey = _apiKey.toString();
      _apiKey = _apiKey.toString();
      if (cpuPressureMonitor) {
        cpuPressureMonitor.startMonitoring();
      }
      if (encryptionSecret) {
        if (!hasE2eeCapability()) {
          setTimeout(sessionConnectFailed.bind(this, otError(
            errors.UNSUPPORTED_BROWSER,
            new Error('E2E Encryption is not supported in your browser.')
          )));
          return this;
        }
        try {
          validateSecret(encryptionSecret);
          this.keyStore.set(sessionId, encryptionSecret);
          _e2eeSecretSet = true;
        } catch (e) {
          setTimeout(sessionConnectFailed.bind(this, otError(
            errors.INVALID_ENCRYPTION_SECRET,
            new Error(`Invalid encryptionSecret: ${e.message}`)
          )));
          return this;
        }
      }

      // TODO: Ugly hack, make sure APIKEY is set

      if (APIKEY.value.length === 0) {
        APIKEY.value = _apiKey;
      }
      const hasStaticConfigUrl = Boolean(StaticConfig.onlyLocal().configUrl);

      const useIpWhitelistConfigUrl = (ipWhitelist === true) &&
        Boolean(StaticConfig.onlyLocal().ipWhitelistConfigUrl);

      if (!hasStaticConfigUrl && !useIpWhitelistConfigUrl) {
        this.logConfigurationFileEvent('Event', {
          message: 'No configUrl, using local config only',
        });
      } else {
        this.logConfigurationFileEvent('Attempt');
      }

      const futureStaticConfigDefault = hasStaticConfigUrl || useIpWhitelistConfigUrl ?
        StaticConfig.get({ partnerId: _apiKey, token, useIpWhitelistConfigUrl, proxyUrl }) :
        Promise.resolve(StaticConfig.onlyLocal());
      const futureStaticConfig = deps.futureStaticConfig || futureStaticConfigDefault;

      futureStaticConfig
        .then(
          (staticConfig) => {
            if (hasStaticConfigUrl || useIpWhitelistConfigUrl) {
              this.logConfigurationFileEvent('Success');
            }
            return staticConfig;
          },
          (err) => {
            if (hasStaticConfigUrl || useIpWhitelistConfigUrl) {
              this.logConfigurationFileEvent('Failure', {
                failureMessage: err.message,
                failureStack: err.stack,
              });
            }
            return StaticConfig.onlyLocal();
          }
        )
        .then((staticConfig) => {
          this.staticConfig = staticConfig;
          analytics.staticConfig = staticConfig;

          if (staticConfig.apiEnabled === false) {
            throw otError(
              errors.API_KEY_DISABLED,
              new Error('The API KEY has been disabled. Access to the service is currently being ' +
                'restricted. Please contact support.')
            );
          }

          if (checkInterrupted()) {
            return undefined;
          }

          this.logConnectivityEvent('Attempt');
          if (proxyUrl) {
            this.logEvent('SessionInfo', 'Attempt', null, {
              proxyUrl,
            });
          } else {
            this.logEvent('SessionInfo', 'Attempt');
          }

          const onSessionInfoError = (error) => {
            // @todo I think we should move convertAnvilErrorCode to after we log the Failure. It's
            // a lossy process, and the more information we have in our logged failure, the better
            // we can understand the failures.
            error.code = convertAnvilErrorCode(error.code);

            this.logConnectivityEvent('Failure', null, {
              failureReason: 'GetSessionInfo',
              failureCode: error.code || 'No code',
              failureMessage: error.message,
              failureName: error.name,
            });

            if (error.name) {
              error = otError(
                error.name,
                new Error(`${error.message}${(error.code ? ` (${error.code})` : '')}`),
                error.code
              );
            }

            sessionConnectFailed.call(this, error);
          };

          const onSessionInfoSuccess = (sessionInfo) => {
            if (checkInterrupted()) {
              return;
            }

            if (sessionInfo.partnerId && sessionInfo.partnerId !== _apiKey) {
              // The apiKey does not match, this is an error
              const reason = 'Authentication Error: The API key does not match the token or session.';

              onSessionInfoError(otError(
                errors.AUTHENTICATION_ERROR,
                new Error(reason),
                ExceptionCodes.AUTHENTICATION_ERROR
              ));
              return;
            }

            // Force SPC if set by either sessionInfo or forced by initSession.
            _useSinglePeerConnection =
              shouldUseSinglePeerConnection(sessionInfo, singlePeerConnection);

            if (_useSinglePeerConnection) {
              _singlePeerConnectionController = new SinglePeerConnectionController(this);
            }

            analytics.sessionInfo = sessionInfo;
            const sessionInfoSuccessLogPayload = {
              features: {
                reconnection: sessionInfo.reconnection,
                renegotiation: hasIceRestartsCapability() &&
                  sessionInfo.renegotiation,
                simulcast: sessionInfo.simulcast === undefined ? false :
                  sessionInfo.simulcast && OTHelpers.env.name === 'Chrome',
              },
            };
            if (proxyUrl) {
              sessionInfoSuccessLogPayload.proxyUrl = proxyUrl;
            }
            if (this.is('connecting')) {
              this.sessionInfo = sessionInfo;
              this._.setIceServers(this.sessionInfo.iceServers);
              _p2p = sessionInfo.p2pEnabled;
              _messagingServer = sessionInfo.messagingServer;
              this.logEvent('SessionInfo', 'Success', null, sessionInfoSuccessLogPayload, {
                messagingServer: sessionInfo.messagingServer,
              });

              /**
               * The only sessionInfo overrides that was being used is
               */
              const overrides = staticConfig.sessionInfoOverrides || {};
              if (_prioritizeVP9) {
                overrides.priorityVideoCodec = 'vp9';
              }

              if (overrides != null && typeof overrides === 'object') {
                Object.keys(overrides)
                  .forEach((key) => { Object.defineProperty(this.sessionInfo, key, { value: overrides[key] }); });
              }

              connectMessenger.call(this);
            }
          };

          const targetUrl = prependProxyToUrlIfNeeded(staticConfig.apiUrl, proxyUrl);

          return getSessionInfo({
            anvilUrl: targetUrl,
            sessionId,
            token: _token,
            connectionId: _connectionId,
            clientVersion: staticConfig.clientVersion,
          })
            .then(onSessionInfoSuccess, onSessionInfoError);
        })
        .catch((err) => {
          sessionConnectFailed.call(this, err);
        });

      return this;
    };

    /**
    * Disconnects from the Vonage Video API session.
    *
    * <p>
    * Calling the <code>disconnect()</code> method ends your connection with the session. In the
    * course of terminating your connection, it also ceases publishing any stream(s) you were
    * publishing.
    * </p>
    * <p>
    * Session objects on remote clients dispatch <code>streamDestroyed</code> events for any
    * stream you were publishing. The Session object dispatches a <code>sessionDisconnected</code>
    * event locally. The Session objects on remote clients dispatch <code>connectionDestroyed</code>
    * events, letting other connections know you have left the session. The
    * {@link SessionDisconnectEvent} and {@link StreamEvent} objects that define the
    * <code>sessionDisconnect</code> and <code>connectionDestroyed</code> events each have a
    * <code>reason</code> property. The <code>reason</code> property lets the developer determine
    * whether the connection is being terminated voluntarily and whether any streams are being
    * destroyed as a byproduct of the underlying connection's voluntary destruction.
    * </p>
    * <p>
    * If the session is not currently connected, calling this method causes a warning to be logged.
    * See <a href="OT.html#setLogLevel">OT.setLogLevel()</a>.
    * </p>
    *
    * <p>
    * <i>Note:</i> If you intend to reuse a Publisher object to publish to different sessions
    * (or the same session) sequentially, add an event listener for the <code>streamDestroyed</code>
    * event dispatched by the Publisher object (when it stops publishing). In the event listener,
    * call the <code>preventDefault()</code> method of the event object to prevent the Publisher's
    * video from being removed from the page.
    * </p>
    *
    * <h5>
    * Events dispatched:
    * </h5>
    * <p>
    * <code>sessionDisconnected</code>
    * (<a href="SessionDisconnectEvent.html">SessionDisconnectEvent</a>)
    * &#151; Dispatched locally when the connection is disconnected.
    * </p>
    * <p>
    * <code>connectionDestroyed</code> (<a href="ConnectionEvent.html">ConnectionEvent</a>) &#151;
    * Dispatched on other clients, along with the <code>streamDestroyed</code> event (as warranted).
    * </p>
    *
    * <p>
    * <code>streamDestroyed</code> (<a href="StreamEvent.html">StreamEvent</a>) &#151;
    * Dispatched on other clients if streams are lost as a result of the session disconnecting.
    * </p>
    *
    * @method #disconnect
    * @memberOf Session
  */
    this.disconnect = function () {
      if (_singlePeerConnectionController) {
        _singlePeerConnectionController.destroy();
        _singlePeerConnectionController = null;
      }
      if (cpuPressureMonitor) {
        cpuPressureMonitor.stopMonitoring();
      }
      if (_socket && _socket.isNot('disconnected')) {
        if (_socket.isNot('disconnecting')) {
          if (!_socket.isNot('connecting')) {
            this.logConnectivityEvent('Cancel');
          }
          setState('disconnecting');
          _socket.disconnect();
          this.off('gsmCallEnded', gsmCallEndedHandler);
        }
      } else {
        reset();
      }
    };

    this.destroy = function () {
      this.streams.destroy();
      this.connections.destroy();
      this.archives.destroy();
      this.disconnect();
    };

    /**
    * The <code>publish()</code> method starts publishing an audio-video stream to the session.
    * The audio-video stream is captured from a local microphone and webcam. Upon successful
    * publishing, the Session objects on all connected clients dispatch the
    * <code>streamCreated</code> event.
    * </p>
    *
    * <!--JS-ONLY-->
    * <p>You pass a Publisher object as the one parameter of the method. You can initialize a
    * Publisher object by calling the <a href="OT.html#initPublisher">OT.initPublisher()</a>
    * method. Before calling <code>Session.publish()</code>.
    * </p>
    *
    * <p>This method takes an alternate form: <code>publish(targetElement: String | HTMLElement,
    * properties: Object, completionHandler: Function): Publisher</code> &#151; In this form, you do
    <i>not</i> pass a Publisher object into the function. Instead, you pass in a <code>targetElement</code>
    (the target HTML element or the ID of the target HTML element for the Publisher), an optional
    <code>properties</code> object that defines options for the Publisher (see
    <a href="OT.html#initPublisher">OT.initPublisher()</a>), and an optional completion handler function.
    * The method returns a new Publisher object, which starts sending an audio-video stream to the
    * session. The remainder of this documentation describes the form that takes a single Publisher
    * object as a parameter.
    *
    * <p>
    *   A local display of the published stream is created on the web page by replacing
    *         the specified element in the DOM with a streaming video display. The video stream
    *         is automatically mirrored horizontally so that users see themselves and movement
    *         in their stream in a natural way. If the width and height of the display do not match
    *         the 4:3 aspect ratio of the video signal, the video stream is cropped to fit the
    *         display.
    * </p>
    *
    * <p>
    *   If calling this method creates a new Publisher object and the Vonage Video API library does not
    *   have access to the camera or microphone, the web page alerts the user to grant access
    *   to the camera and microphone.
    * </p>
    *
    * <p>
    * The OT object dispatches an <code>exception</code> event if the user's role does not
    * include permissions required to publish. For example, if the user's role is set to subscriber,
    * then they cannot publish. You define a user's role when you create the user token
    * (see <a href="https://tokbox.com/developer/guides/create-token/">Token creation overview</a>).
    * You pass the token string as a parameter of the <code>connect()</code> method of the Session
    * object. See <a href="ExceptionEvent.html">ExceptionEvent</a> and
    * <a href="OT.html#on">OT.on()</a>.
    * </p>
    *     <p>
    *     The application throws an error if the session is not connected.
    *     </p>
    *
    * <h5>Events dispatched:</h5>
    * <p>
    * <code>exception</code> (<a href="ExceptionEvent.html">ExceptionEvent</a>) &#151; Dispatched
    * by the OT object. This can occur when user's role does not allow publishing (the
    * <code>code</code> property of event object is set to 1500); it can also occur if the
    * connection fails to connect (the <code>code</code> property of event object is set to 1013).
    * WebRTC is a peer-to-peer protocol, and it is possible that connections will fail to connect.
    * The most common cause for failure is a firewall that the protocol cannot traverse.</li>
    * </p>
    * <p>
    * <code>streamCreated</code> (<a href="StreamEvent.html">StreamEvent</a>) &#151;
    * The stream has been published. The Session object dispatches this on all clients
    * subscribed to the stream, as well as on the publisher's client.
    * </p>
    *
    * <h5>Example</h5>
    *
    * <p>
    *   The following example publishes a video once the session connects:
    * </p>
    * <pre>
    * var apiKey = ""; // Replace with your API key. See https://tokbox.com/account
    * var sessionId = ""; // Replace with your own session ID.
    *                     // https://tokbox.com/developer/guides/create-session/.
    * var token = ""; // Replace with a generated token that has been assigned the publish role.
    *                 // See https://tokbox.com/developer/guides/create-token/.
    * var session = OT.initSession(apiKey, sessionID);
    * session.connect(token, function(error) {
    *   if (error) {
    *     console.log(error.message);
    *   } else {
    *     var publisherOptions = {width: 400, height:300, name:"Bob's stream"};
    *     // This assumes that there is a DOM element with the ID 'publisher':
    *     publisher = OT.initPublisher('publisher', publisherOptions);
    *     session.publish(publisher);
    *   }
    * });
    * </pre>
    *
    * @param {Publisher} publisher A Publisher object, which you initialize by calling the
    * <a href="OT.html#initPublisher">OT.initPublisher()</a> method.
    *
    * @param {Function} completionHandler (Optional) A function to be called when the call to the
    * <code>publish()</code> method succeeds or fails. This function takes one parameter &mdash;
    * <code>error</code>. On success, the <code>completionHandler</code> function is not passed any
    * arguments. On error, the function is passed an <code>error</code> object parameter
    * (see the <a href="Error.html">Error</a> object). The
    * <code>error</code> object has two properties: <code>code</code> (an integer) and
    * <code>message</code> (a string), which identify the cause of the failure. Calling
    * <code>publish()</code> fails if the role assigned to your token is not "publisher" or
    * "moderator"; in this case the <code>error.name</code> property is set to
    * <code>"OT_PERMISSION_DENIED"</code>. Calling <code>publish()</code> also fails if the
    * client fails to connect; in this case the <code>error.name</code> property is set to
    * <code>"OT_NOT_CONNECTED"</code>. The following code adds a completion handler when
    * calling the <code>publish()</code> method:
    * <pre>
    * session.publish(publisher, null, function (error) {
    *   if (error) {
    *     console.log(error.message);
    *   } else {
    *     console.log("Publishing a stream.");
    *   }
    * });
    * </pre>
    *
    * @returns The Publisher object for this stream.
    *
    * @method #publish
    * @memberOf Session
  */
    this.publish = (publisher, properties, completionHandler) => {
      if (typeof publisher === 'function') {
        completionHandler = publisher;
        publisher = undefined;
      }

      if (typeof properties === 'function') {
        completionHandler = properties;
        properties = undefined;
      }

      completionHandler = completionHandler || function () {};

      if (this.isNot('connected')) {
        analytics.logError(
          1010,
          'OT.exception',
          'We need to be connected before you can publish',
          null,
          {
            action: 'Publish',
            variation: 'Failure',
            failureReason: 'unconnected',
            failureCode: ExceptionCodes.NOT_CONNECTED,
            failureMessage: 'We need to be connected before you can publish',
            sessionId: _sessionId,
            streamId: (publisher && publisher.stream) ? publisher.stream.id : null,
            p2p: this.sessionInfo ? this.sessionInfo.p2pEnabled : undefined,
            messagingServer: this.sessionInfo ? this.sessionInfo.messagingServer : null,
            partnerId: _apiKey,
          }
        );

        dispatchOTError(
          otError(
            errors.NOT_CONNECTED,
            new Error('We need to be connected before you can publish'),
            ExceptionCodes.NOT_CONNECTED
          ),
          completionHandler
        );

        return null;
      }

      if (!permittedTo('publish')) {
        const errorMessage = 'This token does not allow publishing. The role must be at least ' +
          '`publisher` to enable this functionality';
        const options = {
          failureReason: 'Permission',
          failureCode: ExceptionCodes.UNABLE_TO_PUBLISH,
          failureMessage: errorMessage,
        };
        this.logEvent('Publish', 'Failure', null, options);

        dispatchOTError(
          otError(
            errors.PERMISSION_DENIED,
            new Error(errorMessage),
            ExceptionCodes.UNABLE_TO_PUBLISH
          ),
          completionHandler
        );

        return null;
      }

      // If the user has passed in an ID or an element then we create a new publisher.
      const shouldInitPublisher = !publisher || typeof (publisher) === 'string' || OTHelpers.isElementNode(publisher);
      // If publisher is an instance of Publisher we use that Publisher.
      const shouldUsePublisher = publisher instanceof Publisher;

      if (!shouldInitPublisher && !shouldUsePublisher) {
        dispatchOTError(
          otError(
            errors.INVALID_PARAMETER,
            new Error(
              'Session.publish :: First parameter passed in is neither a ' +
              'string nor an instance of the Publisher'
            ),
            ExceptionCodes.UNABLE_TO_PUBLISH
          ),
          completionHandler
        );

        return undefined;
      }

      if (shouldUsePublisher) {
        // If the publisher already has a session attached to it we can
        if ('session' in publisher && publisher.session && 'sessionId' in publisher.session) {
          // send a warning message that we can't publish again.
          if (publisher.session.sessionId === this.sessionId) {
            _logging.warn(`Cannot publish ${publisher.guid()} again to ${
              this.sessionId}. Please call session.unpublish(publisher) first.`);
          } else {
            _logging.warn(`Cannot publish ${publisher.guid()} publisher already attached to ${
              publisher.session.sessionId}. Please call session.unpublish(publisher) first.`);
          }
          completionHandler(null, publisher);
          return publisher;
        }
      } else if (shouldInitPublisher) {
        // Initiate a new Publisher with the new session credentials
        publisher = initPublisher(publisher, properties);
      }

      if (_muteOnEntry) {
        publisher._.forceMuteAudio();
      }

      // Add publisher reference to the session
      publisher._.publishToSession(this, analytics)
        .then(
          () => completionHandler(null, publisher),
          (err) => {
            err.message = `Session.publish :: ${err.message}`;
            _logging.error(err.code, err.message);
            completionHandler(err);
          }
        );

      // return the embed publisher
      return publisher;
    };

    /**
    * Ceases publishing the specified publisher's audio-video stream
    * to the session. By default, the local representation of the audio-video stream is
    * removed from the web page. Upon successful termination, the Session object on every
    * connected web page dispatches
    * a <code>streamDestroyed</code> event.
    * </p>
    *
    * <p>
    * To prevent the Publisher from being removed from the DOM, add an event listener for the
    * <code>streamDestroyed</code> event dispatched by the Publisher object and call the
    * <code>preventDefault()</code> method of the event object.
    * </p>
    *
    * <p>
    * <i>Note:</i> If you intend to reuse a Publisher object to publish to different sessions
    * (or the same session) sequentially, add an event listener for the <code>streamDestroyed</code>
    * event dispatched by the Publisher object (when it stops publishing). In the event listener,
    * call the <code>preventDefault()</code> method of the event object to prevent the Publisher's
    * video from being removed from the page.
    * </p>
    *
    * <h5>Events dispatched:</h5>
    *
    * <p>
    * <code>streamDestroyed</code> (<a href="StreamEvent.html">StreamEvent</a>) &#151;
    * The stream associated with the Publisher has been destroyed. Dispatched on by the
    * Publisher on on the Publisher's browser. Dispatched by the Session object on
    * all other connections subscribing to the publisher's stream.
    * </p>
    *
    * <h5>Example</h5>
    *
    * The following example publishes a stream to a session and adds a Disconnect link to the
    * web page. Clicking this link causes the stream to stop being published.
    *
    * <pre>
    * &lt;script&gt;
    *   var apiKey = ""; // Replace with your API key. See https://tokbox.com/account
    *   var sessionID = ""; // Replace with your own session ID.
    *                    // See https://tokbox.com/developer/guides/create-session/.
    *   var token = ""; // Replace with a generated token.
    *                   // See https://tokbox.com/developer/guides/create-token/.
    *   var publisher;
    *   var session = OT.initSession(apiKey, sessionID);
    *   session.connect(token, function(error) {
    *     if (error) {
    *       console.log(error.message);
    *     } else {
    *       // This assumes that there is a DOM element with the ID 'publisher':
    *       publisher = OT.initPublisher('publisher');
    *       session.publish(publisher);
    *     }
    *   });
    *
    *   function unpublish() {
    *     session.unpublish(publisher);
    *   }
    * &lt;/script&gt;
    *
    * &lt;body&gt;
    *
    *     &lt;div id="publisherContainer/&gt;
    *     &lt;br/&gt;
    *
    *     &lt;a href="javascript:unpublish()"&gt;Stop Publishing&lt;/a&gt;
    *
    * &lt;/body&gt;
    *
    * </pre>
    *
    * @see <a href="#publish">publish()</a>
    *
    * @see <a href="StreamEvent.html">streamDestroyed event</a>
    *
    * @param {Publisher} publisher</span> The Publisher object to stop streaming.
    *
    * @method #unpublish
    * @memberOf Session
  */
    this.unpublish = function (publisher) {
      if (!publisher) {
        _logging.error('OT.Session.unpublish: publisher parameter missing.');
        return;
      }

      // Unpublish the localMedia publisher
      publisher._.unpublishFromSession(this, 'unpublished');
    };

    /**
      * Sets the ICE configuration for all connections in a session. This replaces any previously
      * set ICE configurations.
      *
      * <p>
      * This feature is available for projects that use the
      * <a href="https://tokbox.com/developer/guides/configurable-turn-servers/">configurable
      * TURN server add-on</a>.
      *
      * @see <a href="OT.html#initSession">OT.initSession()</a>
      *
      * @param {IceConfig} newIceConfig This object defines the ICE configuration. It has the
      * following propoerties;
      *
      * <p>
      * <ul>
      *   <li>
      *     <code>includeServers</code></code> (String) — Set this to 'custom' and client will use
      *     only the custom TURN servers you provide in the <code>customServers</code> property
      *     of the <code>newIceConfig</code> parameter. Set this to 'all' and the client will use
      *     both the custom TURN servers you provide along with Vonage Video API TURN servers.
      *   </li>
      *
      *   <li>
      *     <code>transportPolicy</code></code> (String) — Set this to 'all' (the default) and
      *     the client will use all ICE transport types (such as host, srflx, and TURN) to establish
      *     media connectivity. Set this to 'relay' to force connectivity through TURN always
      *     and ignore all other ICE candidates.
      *   </li>
      *
      *   <li>
      *     <p>
      *     <code>customServers</code></code> (Array) — Set this to an array of objects defining
      *     your custom TURN servers. Each object corresponds to one custom TURN server, and it
      *     includes the following properties:
      *     </p>
      *     <p>
      *     <ul>
      *       <li>
      *         <code>urls</code> (String or Array of Strings) — A string or an array of strings,
      *         where each string is a URL supported by the TURN server (and this may be only one URL).
      *       </li>
      *
      *       <li>
      *         <code>username</code> (String, optional) — The username for the TURN server defined
      *         in this object.
      *       </li>
      *
      *       <li>
      *         <code>credential</code> (String, optional) — The credential string for the TURN server
      *         defined in this object.
      *       </li>
      *     </ul>
      *     </p>
      *  </li>
      *
      * </ul>
      * </p>
      *
      * @method #setIceConfig
      *
      * @memberOf Session
    */
    this.setIceConfig = async function (newIceConfig) {
      validateIceConfig(newIceConfig);

      analytics.logEvent({
        action: 'setIceConfig',
        variation: 'Attempt',
        payload: { newIceConfig },
      });

      // Future publishers will need the new iceConfig, which needs to be cloned due to getIceConfig behavior
      iceConfig = {
        ...newIceConfig,
      };

      // Include default servers, unless user has includeServers set to 'custom'
      if (newIceConfig.includeServers === 'all') {
        const iceServerInfo = await _session._.getOtIceServerInfo();
        newIceConfig.customServers = newIceConfig.customServers.concat(iceServerInfo.servers);
      }

      const extractServersFromIceConfig = iceConfigObject => ({
        iceServers: iceConfigObject.customServers,
        iceTransportPolicy: iceConfigObject.transportPolicy,
      });

      const processedIceServers = extractServersFromIceConfig(newIceConfig);
      try {
        await Promise.all(sessionObjects.publishers.map(pub =>
          pub._.setIceConfig(processedIceServers)).concat(
          sessionObjects.subscribers.map(sub =>
            sub._.setIceConfig(processedIceServers)
          )));
      } catch (err) {
        analytics.logEvent({
          action: 'setIceConfig',
          variation: 'Failure',
          payload: { newIceConfig },
        });
        throw err;
      }

      analytics.logEvent({
        action: 'setIceConfig',
        variation: 'Success',
        payload: { newIceConfig },
      });
    };

    /**
    * Subscribes to a stream that is available to the session. You can get an array of
    * available streams from the <code>streams</code> property of the <code>sessionConnected</code>
    * and <code>streamCreated</code> events (see
    * <a href="SessionConnectEvent.html">SessionConnectEvent</a> and
    * <a href="StreamEvent.html">StreamEvent</a>).
    * </p>
    * <p>
    * The subscribed stream is displayed on the local web page by replacing the specified element
    * in the DOM with a streaming video display. If the width and height of the display do not
    * match the 4:3 aspect ratio of the video signal, the video stream is cropped to fit
    * the display. If the stream lacks a video component, a blank screen with an audio indicator
    * is displayed in place of the video stream.
    * </p>
    *
    * <p>
    * The application throws an error if the session is not connected<!--JS-ONLY--> or if the
    * <code>targetElement</code> does not exist in the HTML DOM<!--/JS-ONLY-->.
    * </p>
    *
    * <h5>Example</h5>
    *
    * The following code subscribes to other clients' streams:
    *
    * <pre>
    * var apiKey = ""; // Replace with your API key. See https://tokbox.com/account
    * var sessionID = ""; // Replace with your own session ID.
    *                     // See https://tokbox.com/developer/guides/create-session/.
    * var token = ""; // Replace with a generated token.
    *                 // See https://tokbox.com/developer/guides/create-token/.
    *
    * var session = OT.initSession(apiKey, sessionID);
    * session.on("streamCreated", function(event) {
    *   subscriber = session.subscribe(event.stream, targetElement);
    * });
    * session.connect(token);
    * </pre>
    *
    * @param {Stream} stream The Stream object representing the stream to which we are trying to
    * subscribe.
    *
    * @param {Object} targetElement (Optional) The DOM element or the <code>id</code> attribute of
    * the existing DOM element used to determine the location of the Subscriber video in the HTML
    * DOM. See the <code>insertMode</code> property of the <code>properties</code> parameter. If
    * you do not specify a <code>targetElement</code>, the application appends a new DOM element
    * to the HTML <code>body</code>.
    *
    * @param {Object} properties This is an object that contains the following properties:
    *    <ul>
    *       <li><code>audioVolume</code> (Number) &#151; The desired audio volume, between 0 and
    *       100, when the Subscriber is first opened (default: 50). After you subscribe to the
    *       stream, you can adjust the volume by calling the
    *       <a href="Subscriber.html#setAudioVolume"><code>setAudioVolume()</code> method</a> of the
    *       Subscriber object. This volume setting affects local playback only; it does not affect
    *       the stream's volume on other clients.</li>
    *
    *       <li>
    *         <code>fitMode</code> (String) &#151; Determines how the video is displayed if the its
    *           dimensions do not match those of the DOM element. You can set this property to one of
    *           the following values:
    *           <p>
    *           <ul>
    *             <li>
    *               <code>"cover"</code> &mdash; The video is cropped if its dimensions do not match
    *               those of the DOM element. This is the default setting for videos that have a
    *               camera as the source (for Stream objects with the <code>videoType</code> property
    *               set to <code>"camera"</code>).
    *             </li>
    *             <li>
    *               <code>"contain"</code> &mdash; The video is letterboxed if its dimensions do not
    *               match those of the DOM element. This is the default setting for screen-sharing
    *               videos (for Stream objects with the <code>videoType</code> property set to
    *               <code>"screen"</code>).
    *             </li>
    *           </ul>
    *       </li>
    *
    *       <li>
    *       <code>height</code> (Number or String) &#151; The desired initial height of the displayed
    *       video in the HTML page (default: 198 pixels). You can specify the number of pixels as
    *       either a number (such as 300) or a string ending in "px" (such as "300px"). Or you can
    *       specify a percentage of the size of the parent element, with a string ending in "%"
    *       (such as "100%"). <i>Note:</i> To resize the video, adjust the CSS of the subscriber's
    *       DOM element (the <code>element</code> property of the Subscriber object) or (if the
    *       height is specified as a percentage) its parent DOM element (see
    *       <a href="https://tokbox.com/developer/guides/customize-ui/js/#video_resize_reposition">
    *       Resizing or repositioning a video</a>).
    *       </li>
    *       <li>
    *       <strong>insertDefaultUI</strong> (Boolean) &#151; Whether to use the default Vonage Video API UI
    *       (<code>true</code>, the default) or not (<code>false</code>). The default UI element
    *       contains user interface controls, a video loading indicator, and automatic video cropping
    *       or letterboxing, in addition to the video. (If you leave <code>insertDefaultUI</code>
    *       set to <code>true</code>, you can control individual UI settings using the
    *       <code>fitMode</code>, <code>showControls</code>, and <code>style</code> options.)
    *       <p>
    *       If you set this option to <code>false</code>, OpenTok.js does not insert a default UI
    *       element in the HTML DOM, and the <code>element</code> property of the Subscriber object is
    *       undefined. The Subscriber object dispatches a
    *       <a href="Subscriber.html#event:videoElementCreated">videoElementCreated</a> event when
    *       the <code>video</code> element (or in Internet Explorer the <code>object</code> element
    *       containing the video) is created. The <code>element</code> property of the event object
    *       is a reference to the Subscriber's <code>video</code> (or <code>object</code>) element.
    *       Add it to the HTML DOM to display the video.
    *       <p>
    *       Set this option to <code>false</code> if you want to move the Publisher's
    *       <code>video</code> element (or its <code>object</code> element in Internet Explorer) in
    *       the HTML DOM.
    *       <p>
    *       If you set this to <code>false</code>, do not set the <code>targetElement</code>
    *       parameter. (This results in an error passed into to the <code>OT.initPublisher()</code>
    *       callback function.) To add the video to the HTML DOM, add an event listener for the
    *       <code>videoElementCreated</code> event, and then add the <code>element</code> property of
    *       the event object into the HTML DOM.
    *       </li>
    *       <li>
    *         <code>insertMode</code> (String) &#151; Specifies how the Subscriber object will
    *         be inserted in the HTML DOM. See the <code>targetElement</code> parameter. This
    *         string can have the following values:
    *         <p>
    *         <ul>
    *           <li><code>"replace"</code> &#151; The Subscriber object replaces contents of the
    *             targetElement. This is the default.</li>
    *           <li><code>"after"</code> &#151; The Subscriber object is a new element inserted
    *             after the targetElement in the HTML DOM. (Both the Subscriber and targetElement
    *             have the same parent element.)</li>
    *           <li><code>"before"</code> &#151; The Subscriber object is a new element inserted
    *             before the targetElement in the HTML DOM. (Both the Subscriber and targetElement
    *             have the same parent element.)</li>
    *           <li><code>"append"</code> &#151; The Subscriber object is a new element added as a
    *             child of the targetElement. If there are other child elements, the Subscriber is
    *             appended as the last child element of the targetElement.</li>
    *         </ul></p>
    *   <li>
    *   <code>preferredFrameRate</code> (Number) &#151; The preferred frame rate of the subscriber's
    *   video. Lowering the preferred frame rate lowers video quality on the subscribing client,
    *   but it also reduces network and CPU usage. You may want to use a lower frame rate for
    *   subscribers to a stream that is less important than other streams.
    *   <p>
    *   This property only applies when subscribing to a stream that uses the
    *   <a href="https://tokbox.com/developer/guides/scalable-video">
    *   scalable video feature</a>. Scalable video is available:
    *   <ul>
    *   <li>
    *     Only in sessions that use the Vonage Video API Media Router (sessions with the
    *     <a href="https://tokbox.com/developer/guides/create-session/#media-mode">media
    *     mode</a> set to routed).
    *   </li>
    *   <li>
    *     Only for streams published by clients that support scalable video:
    *     clients that use the Vonage Video API iOS SDK (on certain devices), the Vonage Video API
    *     Android SDK (on certain devices), or OpenTok.js in Chrome and Safari.
    *   </li>
    *   </ul>
    *   <p>
    *   In streams that do not use scalable video, setting this property has no effect.
    *   <p>
    *   <b>Note:</b> The frame rate for scalable video streams automatically adjusts for each
    *   subscriber, based on network conditions and CPU usage, even if you do not call this method.
    *   Call this method if you want to set a maximum frame rate for this subscriber.
    *   <p>
    *   <p>
    *   Not every frame rate is available to a subscriber. When you set the preferred frame rate for
    *   the subscriber, OpenTok.js picks the best frame rate available that matches your setting.
    *   The frame rates available are based on the value of the Subscriber object's
    *   <code>stream.frameRate</code> property, which represents the maximum value available for the
    *   stream. The actual frame rates available depend, dynamically, on network and CPU resources
    *   available to the publisher and subscriber.
    *   <p>
    *   You can dynamically change the preferred frame rate used by calling the
    *   <code>setPreferredFrameRate()</code> method of the Subscriber object.
    *   </li>
    *   <li>
    *   <p>
    *   <code>preferredResolution</code> (String | Object) &#151; The preferred resolution of the subscriber's
    *   video. Set this to <code>"auto"</code> (a string, recommended) to have OpenTok.js
    *   set the resolution based on the subscriber's dimensions in the browser.
    *   Or set this to an object with two properties: <code>width</code> and <code>height</code>
    *   (both numbers), such as <code>{width: 320, height: 240}</code>. Lowering the preferred video
    *   resolution lowers video quality on the subscribing client, but it also reduces network and CPU
    *   usage. You may want to use a lower resolution based on the dimensions of subscriber's video on
    *   the web page (which is handled automatically with the <code>"auto"</code> setting).
    *   Or you may want to use a resolution for subscribers to a stream that is less
    *   important (and smaller) than other streams.
    * <p>
    *   <i>Note:</i> The <code>"auto"</code> resolution setting only applies
    *   when you use the default Subscriber Video element created by the SDK.
    *   It does not work if you create your own Video element in response to
    *   the <code>mediaStreamAvailable</code> event (see
    *   <a href="https://tokbox.com/developer/guides/customize-ui/js/#media-stream-available">this topic</a>).
    *   <p>
    *   This property only applies when subscribing to a stream that uses the
    *   <a href="https://tokbox.com/developer/guides/scalable-video">
    *   scalable video feature</a>. Scalable video is available:
    *   <ul>
    *   <li>
    *     Only in sessions that use the Vonage Video API Media Router (sessions with the
    *     <a href="https://tokbox.com/developer/guides/create-session/#media-mode">media
    *     mode</a> set to routed).
    *   </li>
    *   <li>
    *     Only for streams published by clients that support scalable video:
    *     clients that use the Vonage Video API iOS SDK (on certain devices), the Vonage Video API
    *     Android SDK (on certain devices), or OpenTok.js in Chrome and Safari.
    *   </li>
    *   </ul>
    *   <p>
    *   In streams that do not use scalable video, setting this property has no effect.
    *   <p>
    *   Not every resolution is available to a subscriber. When you set the preferred resolution,
    *   OpenTok.js and the video encoder pick the best resolution available that matches your
    *   setting. The resolutions available depend on the resolution of the published stream.
    *   The Subscriber object's <code>stream.resolution</code> property  represents the highest
    *   resolution available for the stream. Each of the resolutions available for a stream will use
    *   the same aspect ratio. The actual resolutions available depend, dynamically, on network
    *   and CPU resources available to the publisher and subscriber.
    *   <p>
    *   You can dynamically change the preferred video resolution used by calling the
    *   <code>setPreferredResolution()</code> method of the Subscriber object.
    *   </li>
    *   <li>
    *   <code>showControls</code> (Boolean) &#151; Whether to display the built-in user interface
    *   controls for the Subscriber (default: <code>true</code>). These controls include the name
    *   display, the audio level indicator, the speaker control button, the video disabled indicator,
    *   and the video disabled warning icon. You can turn off all user interface controls by setting
    *   this property to <code>false</code>. You can control the display of individual user interface
    *   controls by leaving this property set to <code>true</code> (the default) and setting
    *   individual properties of the <code>style</code> property.
    *   </li>
    *   <li>
    *   <code>style</code> (Object) &#151; An object containing properties that define the initial
    *   appearance of user interface controls of the Subscriber. The <code>style</code> object
    *   includes the following properties:
    *     <ul>
    *       <li><code>audioBlockedDisplayMode</code> (String) &mdash; Whether to display
    *       the default audio blocked icon in Subscribers (in browsers where audio
    *       autoplay is blocked). Possible values are: <code>"auto"</code> (the default,
    *       icon is displayed when the audio is disabled) and <code>"off"</code> (the icon
    *       is not displayed). Set this to <code>"off"</code> if you want to display
    *       your own UI element showing that the audio is blocked. In response to an
    *       HTML element dispatching a <code>click</code> event, you can call the
    *       <a href="OT.html#unblockAudio">OT.unblockAudio()</a> method to start audio
    *       playback in this and all other blocked subscribers.</li>
    *
    *       <li><code>audioLevelDisplayMode</code> (String) &mdash; How to display the audio level
    *       indicator. Possible values are: <code>"auto"</code> (the indicator is displayed when the
    *       video is disabled), <code>"off"</code> (the indicator is not displayed), and
    *       <code>"on"</code> (the indicator is always displayed).</li>
    *
    *       <li><code>backgroundImageURI</code> (String) &mdash; A URI for an image to display as
    *       the background image when a video is not displayed. (A video may not be displayed if
    *       you call <code>subscribeToVideo(false)</code> on the Subscriber object). You can pass an
    *       http or https URI to a PNG, JPEG, or non-animated GIF file location. You can also use the
    *       <code>data</code> URI scheme (instead of http or https) and pass in base-64-encrypted
    *       PNG data, such as that obtained from the
    *       <a href="Subscriber.html#getImgData">Subscriber.getImgData()</a> method. (For example,
    *       you could set the property to a value returned by calling <code>getImgData()</code> on
    *       a previous Subscriber object.) If the URL or the image data is invalid, the
    *       property is ignored (the attempt to set the image fails silently).</li>
    *
    *       <li><code>buttonDisplayMode</code> (String) &mdash; How to display the speaker controls
    *       Possible values are: <code>"auto"</code> (controls are displayed when the stream is first
    *       displayed and when the user mouses over the display), <code>"off"</code> (controls are not
    *       displayed), and <code>"on"</code> (controls are always displayed).</li>
    *
    *       <li><code>nameDisplayMode</code> (String) &#151; Whether to display the stream name.
    *       Possible values are: <code>"auto"</code> (the name is displayed when the stream is first
    *       displayed and when the user mouses over the display), <code>"off"</code> (the name is not
    *       displayed), and <code>"on"</code> (the name is always displayed).</li>
    *
    *       <li><code>videoDisabledDisplayMode</code> (String) &#151; Whether to display the video
    *       disabled indicator and video disabled warning icons for a Subscriber. These icons
    *       indicate that the video has been disabled (or is at risk of being disabled for
    *       the warning icon) due to poor stream quality. This style only applies to the Subscriber
    *       object. Possible values are: <code>"auto"</code> (the icons are automatically when the
    *       displayed video is disabled or at risk of being disabled due to poor stream quality),
    *       <code>"off"</code> (do not display the icons), and <code>"on"</code> (display the
    *       icons). The default setting is <code>"auto"</code></li>
    *   </ul>
    *   </li>
    *
    *       <li><code>subscribeToAudio</code> (Boolean) &#151; Whether to initially subscribe to audio
    *       (if available) for the stream (default: <code>true</code>).</li>
    *
    *       <li><code>subscribeToVideo</code> (Boolean) &#151; Whether to initially subscribe to video
    *       (if available) for the stream (default: <code>true</code>).</li>
    *
    *       <li><code>testNetwork</code> (Boolean) &#151; Whether, when subscribing to a stream
    *       published by the local client, you want to have the stream come from the Vonage Video API Media
    *       Router (<code>true</code>) or if you want the DOM to simply to display the local camera's
    *       video (<code>false</code>). Set this to <code>true</code> when you want to use the
    *       <a href="Subscriber.html#getStats">Subscriber.getStats()</a> method to check statistics
    *       for a stream you publish. This setting only applies to streams published by the local
    *       client in a session that uses the Vonage Video API Media Router (sessions with the
    *       <a href="https://tokbox.com/developer/guides/create-session/#media-mode">media mode</a>
    *       set to routed), not in sessions with the media mode set to relayed. The default value is
    *       <code>false</code>.</li>
    *
    *       <li>
    *       <code>width</code> (Number or String) &#151; The desired initial width of the displayed
    *       video in the HTML page (default: 264 pixels). You can specify the number of pixels as
    *       either a number (such as 400) or a string ending in "px" (such as "400px"). Or you can
    *       specify a percentage of the size of the parent element, with a string ending in "%"
    *       (such as "100%"). <i>Note:</i> To resize the video, adjust the CSS of the subscriber's
    *       DOM element (the <code>element</code> property of the Subscriber object) or (if the
    *       width is specified as a percentage) its parent DOM element (see
    *       <a href="https://tokbox.com/developer/guides/customize-ui/js/#video_resize_reposition">
    *       Resizing or repositioning a video</a>).
    *       </li>
    *
    *    </ul>
    *
    * @param {Function} completionHandler (Optional) A function to be called when the call to the
    * <code>subscribe()</code> method succeeds or fails. This function takes one parameter &mdash;
    * <code>error</code>. On success, the <code>completionHandler</code> function is not passed any
    * arguments. On error, the function is passed an <code>error</code> object, defined by the
    * <a href="Error.html">Error</a> class, has two properties: <code>code</code> (an integer) and
    * <code>message</code> (a string), which identify the cause of the failure. The following
    * code adds a <code>completionHandler</code> when calling the <code>subscribe()</code> method:
    * <pre>
    * session.subscribe(stream, "subscriber", null, function (error) {
    *   if (error) {
    *     console.log(error.message);
    *   } else {
    *     console.log("Subscribed to stream: " + stream.id);
    *   }
    * });
    * </pre>
    *
    * @signature subscribe(stream, targetElement, properties, completionHandler)
    * @returns {Subscriber} The Subscriber object for this stream. Stream control functions
    * are exposed through the Subscriber object.
    * @method #subscribe
    * @memberOf Session
  */
    this.subscribe = function (stream, targetElement, properties, completionHandler) {
      if (typeof targetElement === 'function') {
        completionHandler = targetElement;
        targetElement = undefined;
        properties = undefined;
      }

      if (typeof properties === 'function') {
        completionHandler = properties;
        properties = undefined;
      }

      completionHandler = completionHandler || function () {};

      if (!this.connection || !this.connection.connectionId) {
        dispatchOTError(
          otError(
            errors.NOT_CONNECTED,
            new Error('Session.subscribe :: Connection required to subscribe'),
            ExceptionCodes.UNABLE_TO_SUBSCRIBE
          ),
          completionHandler
        );

        return undefined;
      }

      if (!stream) {
        dispatchOTError(
          otError(
            errors.INVALID_PARAMETER,
            new Error('Session.subscribe :: stream cannot be null'),
            ExceptionCodes.UNABLE_TO_SUBSCRIBE
          ),
          completionHandler
        );

        return undefined;
      }

      if (!Object.prototype.hasOwnProperty.call(stream, 'streamId')) {
        dispatchOTError(
          otError(
            errors.INVALID_PARAMETER,
            new Error('Session.subscribe :: invalid stream object'),
            ExceptionCodes.UNABLE_TO_SUBSCRIBE
          ),
          completionHandler
        );

        return undefined;
      }

      if (properties && properties.insertDefaultUI === false && targetElement) {
        dispatchOTError(
          otError(
            errors.INVALID_PARAMETER,
            new Error('You cannot specify a target element if insertDefaultUI is false'),
            ExceptionCodes.INVALID_PARAMETER
          ),
          completionHandler
        );

        return undefined;
      }

      if (targetElement && targetElement.insertDefaultUI === false) {
        // You can omit the targetElement property if you set insertDefaultUI to false
        properties = targetElement;
        targetElement = undefined;
      }

      const subscriber = new Subscriber(targetElement, assign(properties || {}, {
        stream,
        session: this, // @todo this needs to go.
        analytics,
        _singlePeerConnectionController,
      }), (err) => {
        if (err) {
          dispatchOTError(err, completionHandler);
          return;
        }

        completionHandler(null, subscriber);
      });

      sessionObjects.subscribers.add(subscriber);

      return subscriber;
    };

    /**
    * Stops subscribing to a stream in the session. the display of the audio-video stream is
    * removed from the local web page.
    *
    * <h5>Example</h5>
    * <p>
    * The following code subscribes to other clients' streams. For each stream, the code also
    * adds an Unsubscribe link.
    * </p>
    * <pre>
    * var apiKey = ""; // Replace with your API key. See See https://tokbox.com/account
    * var sessionID = ""; // Replace with your own session ID.
    *                     // See https://tokbox.com/developer/guides/create-session/.
    * var token = ""; // Replace with a generated token.
    *                 // See https://tokbox.com/developer/guides/create-token/.
    * var streams = [];
    *
    * var session = OT.initSession(apiKey, sessionID);
    * session.on("streamCreated", function(event) {
    *     var stream = event.stream;
    *     displayStream(stream);
    * });
    * session.connect(token);
    *
    * function displayStream(stream) {
    *     var div = document.createElement('div');
    *     div.setAttribute('id', 'stream' + stream.streamId);
    *
    *     var subscriber = session.subscribe(stream, div);
    *     subscribers.push(subscriber);
    *
    *     var aLink = document.createElement('a');
    *     aLink.setAttribute('href', 'javascript: unsubscribe("' + subscriber.id + '")');
    *     aLink.innerHTML = "Unsubscribe";
    *
    *     var streamsContainer = document.getElementById('streamsContainer');
    *     streamsContainer.appendChild(div);
    *     streamsContainer.appendChild(aLink);
    *
    *     streams = event.streams;
    * }
    *
    * function unsubscribe(subscriberId) {
    *     console.log("unsubscribe called");
    *     for (var i = 0; i &lt; subscribers.length; i++) {
    *         var subscriber = subscribers[i];
    *         if (subscriber.id == subscriberId) {
    *             session.unsubscribe(subscriber);
    *         }
    *     }
    * }
    * </pre>
    *
    * @param {Subscriber} subscriber The Subscriber object to unsubcribe.
    *
    * @see <a href="#subscribe">subscribe()</a>
    *
    * @method #unsubscribe
    * @memberOf Session
  */
    this.unsubscribe = function (subscriber) {
      if (!subscriber) {
        const errorMsg = 'OT.Session.unsubscribe: subscriber cannot be null';
        _logging.error(errorMsg);
        throw new Error(errorMsg);
      }

      if (!subscriber.stream) {
        _logging.warn('OT.Session.unsubscribe:: tried to unsubscribe a subscriber that had no stream');
        return false;
      }

      _logging.debug(`OT.Session.unsubscribe: subscriber ${subscriber.id}`);

      subscriber._destroy({ reason: 'Unsubscribe' });

      return true;
    };

    /**
    * Returns an array of local Subscriber objects for a given stream.
    *
    * @param {Stream} stream The stream for which you want to find subscribers.
    *
    * @returns {Array} An array of {@link Subscriber} objects for the specified stream.
    *
    * @see <a href="#unsubscribe">unsubscribe()</a>
    * @see <a href="Subscriber.html">Subscriber</a>
    * @see <a href="StreamEvent.html">StreamEvent</a>
    * @method #getSubscribersForStream
    * @memberOf Session
  */
    this.getSubscribersForStream = function (stream) {
      return sessionObjects.subscribers.where({ streamId: stream.id });
    };

    /**
    * Returns the local Publisher object for a given stream.
    *
    * @param { Stream } stream The stream for which you want to find the Publisher.
    *
    * @returns { Publisher } A Publisher object for the specified stream. Returns
    * <code>null</code> if there is no local Publisher object
    * for the specified stream.
    *
    * @see <a href="#forceUnpublish">forceUnpublish()</a>
    * @see <a href="Subscriber.html">Subscriber</a>
    * @see <a href="StreamEvent.html">StreamEvent</a>
    *
    * @method #getPublisherForStream
    * @memberOf Session
  */
    this.getPublisherForStream = function (stream) {
      let streamId;
      let errorMsg;

      if (typeof stream === 'string') {
        streamId = stream;
      } else if (typeof stream === 'object' && stream && Object.hasOwnProperty.call(stream, 'id')) {
        streamId = stream.id;
      } else {
        errorMsg = 'Session.getPublisherForStream :: Invalid stream type';
        _logging.error(errorMsg);
        throw new Error(errorMsg);
      }

      return sessionObjects.publishers.where({ streamId })[0];
    };

    // Private Session API: for internal OT use only

    this._ = {
      getProxyUrl() {
        return proxyUrl;
      },

      isE2ee() {
        return _session.sessionInfo.e2ee &&
          _e2eeSecretSet &&
          !!_session.capabilities.supportsE2ee;
      },
      isSpc() {
        return _useSinglePeerConnection;
      },

      isSocketReconnecting() {
        return _isSocketReconnecting;
      },

      isSocketConnected() {
        return _socket.is('connected') && !_isSocketReconnecting;
      },

      getSocket() { return _socket; },

      reconnecting: function () {
        _isSocketReconnecting = true;
        this.dispatchEvent(new Events.SessionReconnectingEvent());
      }.bind(this),

      reconnected: function () {
        _isSocketReconnecting = false;
        this.dispatchEvent(new Events.SessionReconnectedEvent());

        if (this.sessionInfo.reconnection) {
          sessionObjects.publishers.where({ session: this }).forEach((publisher) => {
            publisher._.iceRestart();
          });
          if (!this.session.sessionInfo.p2pEnabled) {
            sessionObjects.subscribers.where({ session: this }).forEach((subscriber) => {
              subscriber._.iceRestart('socket reconnected');
            });
          }
        }
      }.bind(this),

      // session.on("signal", function(SignalEvent))
      // session.on("signal:{type}", function(SignalEvent))
      dispatchSignal: function (fromConnection, type, data) {
        const event = new Events.SignalEvent(type, data, fromConnection);
        event.target = this;

        // signal a "signal" event
        // NOTE: trigger doesn't support defaultAction, and therefore preventDefault.
        this.trigger(eventNames.SIGNAL, event);

        // signal an "signal:{type}" event" if there was a custom type
        if (type) { this.dispatchEvent(event); }
      }.bind(this),

      dispatchCaption: function (fromConnection, data) {
        let caption;
        let streamId;
        let isFinal;
        try {
          const parsedData = JSON.parse(data.data);
          caption = parsedData.caption.text;
          streamId = parsedData.streamId;
          isFinal = parsedData.isFinal;
        } catch (error) {
          _logging.error(`Caption parsing failed: ${error.message}`);
          return;
        }

        const event = new Events.CaptionReceivedEvent(caption, streamId, isFinal);
        event.target = this;

        this.trigger(eventNames.SUBSCRIBER_CAPTION_RECEIVED, event);

        this.dispatchEvent(event);
      }.bind(this),

      subscriberChannelUpdate(stream, subscriber, channel, attributes) {
        if (!_socket) {
          _logging.warn('You are disconnected, cannot update subscriber properties ', attributes);
          return null;
        }
        return _socket.subscriberChannelUpdate(stream.id, subscriber.widgetId, channel.id,
          attributes);
      },

      streamCreate({
        name, streamId, subscriberAudioFallbackEnabled, channels, minBitrate, sourceStreamId, e2ee, publisherAudioFallbackEnabled, customProperties } = {}
      , completion) {
        if (!_socket) {
          _logging.warn('You are disconnected, cannot create stream ', streamId);
          return;
        }

        _socket.streamCreate({
          name,
          streamId,
          subscriberAudioFallbackEnabled,
          channels,
          minBitrate,
          maxBitrate: undefined, // Do not expose maxBitrate to the end user,
          sourceStreamId,
          e2ee,
          publisherAudioFallbackEnabled,
          customProperties,
        },
        completion
        );
      },

      streamDestroy(streamId, sourceStreamId) {
        if (!_socket) {
          _logging.warn('You are disconnected, cannot destroy stream ', streamId);
          return;
        }
        _socket.streamDestroy(streamId, sourceStreamId);
      },

      streamChannelUpdate(stream, channel, attributes) {
        if (!_socket) {
          _logging.warn('You are disconnected, cannot update stream properties ', attributes);
          return;
        }
        _socket.streamChannelUpdate(stream.id, channel.id, attributes);
      },

      // allow these variables to be overridden in unit tests
      // it's dirty, but I figure it can be cleaned up when we implement proper DI for our unit tests
      setSocket(newSocket) {
        _socket = newSocket;
      },

      setLogging(newLogging) {
        _logging = newLogging;
      },

      setState,

      setIceServers(iceServers) {
        if (!iceServers) {
          return;
        }

        _iceServerDetails = {
          iceServers: adaptIceServers(iceServers),
          timestamp: Date.now(),
        };
      },

      getOtIceServerInfo() {
        const timeElapsed = !_iceServerDetails ? Infinity : Date.now() - _iceServerDetails.timestamp;
        const validDuration = 24 * 60 * 60 * 1000; // 24 hours
        const validTimeRemaining = validDuration - timeElapsed;
        const fiveMinutes = 5 * 60 * 1000;

        if (validTimeRemaining > fiveMinutes) {
          return Promise.resolve({
            transportPolicy: _session && _session.sessionInfo && _session.sessionInfo.clientCandidates,
            servers: _iceServerDetails && _iceServerDetails.iceServers,
          });
        }

        if (!_token) {
          // @todo why would this happen before connect() where a token is set?

          // Need a token for getting ICE servers from GSI
          return Promise.resolve({
            transportPolicy: _session && _session.sessionInfo && _session.sessionInfo.clientCandidates,
            servers: [],
            needRumorIceServersFallback: true,
          });
        }

        const clientVersion = localStaticConfig.clientVersion;
        return getSessionInfo({
          anvilUrl: (this.staticConfig || localStaticConfig).apiUrl,
          sessionId,
          token: _token,
          connectionId: _connectionId,
          clientVersion,
        })
          .then((sessionInfo) => {
            _session._.setIceServers(sessionInfo.iceServers);

            if (!_iceServerDetails) {
              // No ICE servers provided by GSI
              return {
                transportPolicy: _session.sessionInfo.clientCandidates,
                servers: [],
                needRumorIceServersFallback: true,
              };
            }

            return {
              transportPolicy: _session && _session.sessionInfo && _session.sessionInfo.clientCandidates,
              servers: _iceServerDetails && _iceServerDetails.iceServers,
            };
          });
      },

      getCodecFlags: () => ({
        h264: _session.sessionInfo.h264,
        vp9: _session.sessionInfo.vp9,
        vp8: _session.sessionInfo.vp8,
      }),

      getVideoCodecsCompatible: webRTCStream => testRemovingVideoCodecs({
        RTCPeerConnection: windowMock.RTCPeerConnection,
        env: OTHelpers.env,
        stream: webRTCStream,
        codecFlags: _session._.getCodecFlags(),
      }),

      getIceConfig: () => {
        if (!iceConfig) {
          return _session._.getOtIceServerInfo();
        }

        const transportPolicy = (() => {
          if (
            iceConfig &&
            iceConfig.transportPolicy === 'relay'
          ) {
            return 'relay';
          }

          return _session.sessionInfo.clientCandidates;
        })();

        const otIceServersInfoPromise = (
          iceConfig.includeServers === 'custom' ?
            Promise.resolve({ servers: [] }) :
            _session._.getOtIceServerInfo()
        );

        return otIceServersInfoPromise
          .then(otIceServerInfo => assign(otIceServerInfo, {
            transportPolicy,
            servers: [...otIceServerInfo.servers, ...iceConfig.customServers],
          }));
      },

      forceMute: function (muteForcedInfo) {
        this.dispatchEvent(new Events.MuteForcedEvent(muteForcedInfo));
      }.bind(this),

      enableMuteOnEntry: () => {
        _muteOnEntry = true;
      },

      addSubscriberToPeerConnectionsQueue: (pc, message) => {
        _subscribersQueue.push({ pc, message });
        if (!_subscribersQueueInterval) {
          _subscribersQueueInterval = setInterval(() => {
            const offer = _subscribersQueue.shift();
            if (offer) {
              offer.pc.processMessage('offer', offer.message);
            } else {
              clearInterval(_subscribersQueueInterval);
              _subscribersQueueInterval = null;
            }
          }, 100);
        }
      },

      disableMuteOnEntry: () => {
        _muteOnEntry = false;
      },

      privateEvents: new EventEmitter(),
    };

    /**
    * Sends a signal to each client or a specified client in the session. Specify a
    * <code>to</code> property of the <code>signal</code> parameter to limit the signal to
    * be sent to a specific client; otherwise the signal is sent to each client connected to
    * the session.
    * <p>
    * The following example sends a signal of type "foo" with a specified data payload ("hello")
    * to all clients connected to the session:
    * <pre>
    * session.signal({
    *     type: "foo",
    *     data: "hello"
    *   },
    *   function(error) {
    *     if (error) {
    *       console.log("signal error: " + error.message);
    *     } else {
    *       console.log("signal sent");
    *     }
    *   }
    * );
    * </pre>
    * <p>
    * Calling this method without specifying a recipient client (by setting the <code>to</code>
    * property of the <code>signal</code> parameter) results in multiple signals sent (one to each
    * client in the session). For information on charges for signaling, see the
    * <a href="https://www.vonage.com/communications-apis/video/pricing/">Vonage Video API pricing</a> page.
    * <p>
    * The following example sends a signal of type "foo" with a data payload ("hello") to a
    * specific client connected to the session:
    * <pre>
    * session.signal({
    *     type: "foo",
    *     to: recipientConnection; // a Connection object
    *     data: "hello"
    *   },
    *   function(error) {
    *     if (error) {
    *       console.log("signal error: " + error.message);
    *     } else {
    *       console.log("signal sent");
    *     }
    *   }
    * );
    * </pre>
    * <p>
    * Add an event handler for the <code>signal</code> event to listen for all signals sent in
    * the session. Add an event handler for the <code>signal:type</code> event to listen for
    * signals of a specified type only (replace <code>type</code>, in <code>signal:type</code>,
    * with the type of signal to listen for). The Session object dispatches these events. (See
    * <a href="#events">events</a>.)
    *
    * @param {Object} signal An object that contains the following properties defining the signal:
    * <ul>
    *   <li><code>data</code> &mdash; (String) The data to send. The limit to the length of data
    *     string is 8kB. Do not set the data string to <code>null</code> or
    *     <code>undefined</code>.</li>
    *   <li><code>retryAfterReconnect</code>&mdash; (Boolean) Upon reconnecting to the session,
    *     whether to send any signals that were initiated while disconnected. If your client loses its
    *     connection to the Vonage Video API session, due to a drop in network connectivity, the client
    *     attempts to reconnect to the session, and the Session object dispatches a
    *     <code>reconnecting</code> event. By default, signals initiated while disconnected are
    *     sent when (and if) the client reconnects to the Vonage Video API session. You can prevent this by
    *     setting the <code>retryAfterReconnect</code> property to <code>false</code>. (The default
    *     value is <code>true</code>.)
    *   <li><code>to</code> &mdash; (Connection) A <a href="Connection.html">Connection</a>
    *      object corresponding to the client that the message is to be sent to. If you do not
    *      specify this property, the signal is sent to all clients connected to the session.</li>
    *   <li><code>type</code> &mdash; (String) The type of the signal. You can use the type to
    *     filter signals when setting an event handler for the <code>signal:type</code> event
    *     (where you replace <code>type</code> with the type string). The maximum length of the
    *     <code>type</code> string is 128 characters, and it must contain only letters (A-Z and a-z),
    *     numbers (0-9), '-', '_', and '~'.</li>
    *   </li>
    * </ul>
    *
    * <p>Each property is optional. If you set none of the properties, you will send a signal
    * with no data or type to each client connected to the session.</p>
    *
    * @param {Function} completionHandler (Optional) A function that is called when sending the signal
    * succeeds or fails. This function takes one parameter &mdash; <code>error</code>.
    * On success, the <code>completionHandler</code> function is not passed any
    * arguments. On error, the function is passed an <code>error</code> object, defined by the
    * <a href="Error.html">Error</a> class. The <code>error</code> object has the following
    * properties:
    *
    * <ul>
    *   <li><code>code</code> &mdash; (Number) An error code, which can be one of the following:
    *     <table class="docs_table">
    *         <tr>
    *           <td>400</td> <td>One of the signal properties is invalid.</td>
    *         </tr>
    *         <tr>
    *           <td>404</td> <td>The client specified by the <code>to</code> property is not connected
    *                        to the session.</td>
    *         </tr>
    *         <tr>
    *           <td>413</td> <td>The <code>type</code> string exceeds the maximum length (128 bytes),
    *                        or the <code>data</code> string exceeds the maximum size (8 kB).</td>
    *         </tr>
    *         <tr>
    *           <td>500</td> <td>You are not connected to the Vonage Video API session.</td>
    *         </tr>
    *      </table>
    *   </li>
    *   <li><code>message</code> &mdash; (String) A description of the error.</li>
    * </ul>
    *
    * <p>Note that the <code>completionHandler</code> success result (<code>error == null</code>)
    * indicates that the options passed into the <code>Session.signal()</code> method are valid
    * and the signal was sent. It does <i>not</i> indicate that the signal was successfully
    * received by any of the intended recipients.
    *
    * @method #signal
    * @memberOf Session
    * @see <a href="#event:signal">signal</a> and <a href="#event:signal:type">signal:type</a> events
  */
    this.signal = function (options, completion) {
      let _options = options;
      let _completion = completion || function () {};

      if (isFunction(_options)) {
        _completion = _options;
        _options = null;
      }

      if (this.isNot('connected')) {
        const notConnectedErrorMsg = 'Unable to send signal - you are not connected to the session.';
        dispatchOTError(
          otError(errors.NOT_CONNECTED, new Error(notConnectedErrorMsg), 500),
          _completion
        );
        return;
      }

      function getErrorNameFromCode(code) {
        switch (code) {
          case 400:
          case 413:
            return errors.INVALID_PARAMETER;
          case 429:
            return errors.RATE_LIMIT_EXCEEDED;
          case 404:
            return errors.NOT_FOUND;
          case 500:
            return errors.NOT_CONNECTED;
          case 403:
            return errors.PERMISSION_DENIED;
          case 2001:
            return errors.UNEXPECTED_SERVER_RESPONSE;
          default:
            return undefined;
        }
      }

      _socket.signal(
        _options,
        (error, ...args) => {
          if (error) {
            const errorName = getErrorNameFromCode(error.code);
            if (errorName) {
              error = otError(errorName, new Error(error.message), error.code);
            }
            _completion(error);
            return;
          }
          _completion(error, ...args);
        },
        this.logEvent
      );
      if (options && options.data && typeof options.data !== 'string') {
        _logging.warn('Signaling of anything other than Strings is deprecated. ' +
                'Please update the data property to be a string.');
      }
    };

    /**
    *   Forces a remote connection to leave the session.
    *
    * <p>
    *   The <code>forceDisconnect()</code> method is normally used as a moderation tool
    *        to remove users from an ongoing session.
    * </p>
    * <p>
    *   When a connection is terminated using the <code>forceDisconnect()</code>,
    *        <code>sessionDisconnected</code>, <code>connectionDestroyed</code> and
    *        <code>streamDestroyed</code> events are dispatched in the same way as they
    *        would be if the connection had terminated itself using the <code>disconnect()</code>
    *        method. However, the <code>reason</code> property of a {@link ConnectionEvent} or
    *        {@link StreamEvent} object specifies <code>"forceDisconnected"</code> as the reason
    *        for the destruction of the connection and stream(s).
    * </p>
    * <p>
    *   While you can use the <code>forceDisconnect()</code> method to terminate your own connection,
    *        calling the <code>disconnect()</code> method is simpler.
    * </p>
    * <p>
    *   The OT object dispatches an <code>exception</code> event if the user's role
    *   does not include permissions required to force other users to disconnect.
    *   You define a user's role when you create the user token (see the
    *   <a href="https://tokbox.com/developer/guides/create-token/">Token creation overview</a>).
    *   See <a href="ExceptionEvent.html">ExceptionEvent</a> and <a href="OT.html#on">OT.on()</a>.
    * </p>
    * <p>
    *   The application throws an error if the session is not connected.
    * </p>
    *
    * <h5>Events dispatched:</h5>
    *
    * <p>
    *   <code>connectionDestroyed</code> (<a href="ConnectionEvent.html">ConnectionEvent</a>) &#151;
    *     On clients other than which had the connection terminated.
    * </p>
    * <p>
    *   <code>exception</code> (<a href="ExceptionEvent.html">ExceptionEvent</a>) &#151;
    *     The user's role does not allow forcing other user's to disconnect (<code>event.code =
    *     1530</code>),
    *   or the specified stream is not publishing to the session (<code>event.code = 1535</code>).
    * </p>
    * <p>
    *   <code>sessionDisconnected</code>
    *   (<a href="SessionDisconnectEvent.html">SessionDisconnectEvent</a>) &#151;
    *     On the client which has the connection terminated.
    * </p>
    * <p>
    *   <code>streamDestroyed</code> (<a href="StreamEvent.html">StreamEvent</a>) &#151;
    *     If streams are stopped as a result of the connection ending.
    * </p>
    *
    * @param {Connection} connection The connection to be disconnected from the session.
    * This value can either be a <a href="Connection.html">Connection</a> object or a connection
    * ID (which can be obtained from the <code>connectionId</code> property of the Connection object).
    *
    * @param {Function} completionHandler (Optional) A function to be called when the call to the
    * <code>forceDiscononnect()</code> method succeeds or fails. This function takes one parameter
    * &mdash; <code>error</code>. On success, the <code>completionHandler</code> function is
    * not passed any arguments. On error, the function is passed an <code>error</code> object
    * parameter. The <code>error</code> object, defined by the <a href="Error.html">Error</a>
    * class, has two properties: <code>code</code> (an integer)
    * and <code>message</code> (a string), which identify the cause of the failure.
    * Calling <code>forceDisconnect()</code> fails if the role assigned to your
    * token is not "moderator"; in this case the <code>error.name</code> property is set to
    * <code>"OT_PERMISSION_DENIED"</code>. The following code adds a <code>completionHandler</code>
    * when calling the <code>forceDisconnect()</code> method:
    * <pre>
    * session.forceDisconnect(connection, function (error) {
    *   if (error) {
    *     console.log(error);
    *   } else {
    *     console.log("Connection forced to disconnect: " + connection.id);
    *   }
    * });
    * </pre>
    *
    * @method #forceDisconnect
    * @memberOf Session
  */

    this.forceDisconnect = function (connectionOrConnectionId, completionHandler) {
      if (this.isNot('connected')) {
        const notConnectedErrorMsg = 'Cannot call forceDisconnect(). You are not ' +
                                   'connected to the session.';
        dispatchOTError(
          otError(
            errors.NOT_CONNECTED,
            new Error(notConnectedErrorMsg),
            ExceptionCodes.NOT_CONNECTED
          ),
          completionHandler
        );
        return;
      }

      const connectionId = (
        typeof connectionOrConnectionId === 'string' ?
          connectionOrConnectionId :
          connectionOrConnectionId.id
      );

      const invalidParameterErrorMsg = (
        'Invalid Parameter. Check that you have passed valid parameter values into the method call.'
      );

      if (!connectionId) {
        dispatchOTError(
          otError(
            errors.INVALID_PARAMETER,
            new Error(invalidParameterErrorMsg),
            ExceptionCodes.INVALID_PARAMETER
          ),
          completionHandler
        );

        return;
      }

      const notPermittedErrorMsg = 'This token does not allow forceDisconnect. ' +
        'The role must be at least `moderator` to enable this functionality';

      if (!permittedTo('forceDisconnect')) {
        dispatchOTError(
          otError(
            errors.PERMISSION_DENIED,
            new Error(notPermittedErrorMsg),
            ExceptionCodes.UNABLE_TO_FORCE_DISCONNECT
          ),
          completionHandler
        );

        return;
      }

      _socket.forceDisconnect(connectionId, (err, ...args) => {
        if (err) {
          dispatchOTError(
            otError(
              errors.INVALID_PARAMETER,
              new Error(invalidParameterErrorMsg),
              ExceptionCodes.INVALID_PARAMETER
            ),
            completionHandler
          );
        } else if (completionHandler && isFunction(completionHandler)) {
          completionHandler(err, ...args);
        }
      });
    };

    /**
    * Forces the publisher of the specified stream to stop publishing the stream.
    *
    * <p>
    * Calling this method causes the Session object to dispatch a <code>streamDestroyed</code>
    * event on all clients that are subscribed to the stream (including the client that is
    * publishing the stream). The <code>reason</code> property of the StreamEvent object is
    * set to <code>"forceUnpublished"</code>.
    * </p>
    * <p>
    * The OT object dispatches an <code>exception</code> event if the user's role
    * does not include permissions required to force other users to unpublish.
    * You define a user's role when you create the user token (see the
    * <a href="https://tokbox.com/developer/guides/create-token/">Token creation overview</a>).
    * You pass the token string as a parameter of the <code>connect()</code> method of the Session
    * object. See <a href="ExceptionEvent.html">ExceptionEvent</a> and
    * <a href="OT.html#on">OT.on()</a>.
    * </p>
    *
    * <h5>Events dispatched:</h5>
    *
    * <p>
    *   <code>exception</code> (<a href="ExceptionEvent.html">ExceptionEvent</a>) &#151;
    *     The user's role does not allow forcing other users to unpublish.
    * </p>
    * <p>
    *   <code>streamDestroyed</code> (<a href="StreamEvent.html">StreamEvent</a>) &#151;
    *     The stream has been unpublished. The Session object dispatches this on all clients
    *     subscribed to the stream, as well as on the publisher's client.
    * </p>
    *
    * @param {Stream} stream The stream to be unpublished.
    *
    * @param {Function} completionHandler (Optional) A function to be called when the call to the
    * <code>forceUnpublish()</code> method succeeds or fails. This function takes one parameter
    * &mdash; <code>error</code>. On success, the <code>completionHandler</code> function is
    * not passed any arguments. On error, the function is passed an <code>error</code> object
    * parameter. The <code>error</code> object, defined by the <a href="Error.html">Error</a>
    * class, has two properties: <code>code</code> (an integer)
    * and <code>message</code> (a string), which identify the cause of the failure. Calling
    * <code>forceUnpublish()</code> fails if the role assigned to your token is not "moderator";
    * in this case the <code>error.name</code> property is set to <code>"OT_PERMISSION_DENIED"</code>.
    * The following code adds a completion handler when calling the <code>forceUnpublish()</code>
    * method:
    * <pre>
    * session.forceUnpublish(stream, function (error) {
    *   if (error) {
    *       console.log(error);
    *     } else {
    *       console.log("Connection forced to disconnect: " + connection.id);
    *     }
    *   });
    * </pre>
    *
    * @method #forceUnpublish
    * @memberOf Session
  */

    this.forceUnpublish = (streamOrStreamId, completionHandler = () => {}) => {
      const dispatchError = err => dispatchOTError(otError(err.name, new Error(err.msg), err.code), completionHandler);
      const invalidParameterError = {
        msg: 'Invalid Parameter. Check that you have passed valid parameter values into the method call.',
        code: ExceptionCodes.INVALID_PARAMETER,
        name: errors.INVALID_PARAMETER,
      };

      const notConnectedError = {
        msg: 'Cannot call forceUnpublish(). You are not connected to the session.',
        code: ExceptionCodes.NOT_CONNECTED,
        name: errors.NOT_CONNECTED,
      };

      const notPermittedError = {
        msg: 'This token does not allow forceUnpublish. The role must be at least `moderator` to enable this ' +
          'functionality',
        code: ExceptionCodes.UNABLE_TO_FORCE_UNPUBLISH,
        name: errors.PERMISSION_DENIED,
      };

      const notFoundError = {
        msg: 'The stream does not exist.',
        name: errors.NOT_FOUND,
      };

      const unexpectedError = {
        msg: 'An unexpected error occurred.',
        name: errors.UNEXPECTED_SERVER_RESPONSE,
        code: ExceptionCodes.UNEXPECTED_SERVER_RESPONSE,
      };

      if (!streamOrStreamId) {
        dispatchError(invalidParameterError);
        return;
      }

      if (this.isNot('connected')) {
        dispatchError(notConnectedError);
        return;
      }

      const stream = getStream(streamOrStreamId);

      if (!permittedTo('forceUnpublish')) {
        // if this throws an error the handleJsException won't occur
        dispatchError(notPermittedError);
        return;
      }

      _socket.forceUnpublish(stream.id, (err) => {
        if (!err) {
          completionHandler(null);
          return;
        }
        if (err.code === '404') {
          dispatchError(notFoundError);
        } else if (err.code === '403') {
          dispatchError(notPermittedError);
        } else {
          dispatchError(unexpectedError);
        }
      });
    };

    /**
   * Forces a the publisher of a specified stream to mute its audio.
   *
   * <p>
   * Calling this method causes the Publisher object in the client publishing the
   * stream to dispatch a <code>muteForced</code> event.
   * </p>
   *
   * <p>
   * Check the <code>capabilities.canForceMute</code> property of the Session object to see if
   * you can call this function successfully. This is reserved for clients that have connected
   * with a token that has been assigned the moderator role (see the
   * <a href="https://tokbox.com/developer/guides/create-token/">Token Creation</a>
   * documentation).
   *
   * @param { Stream } stream The stream to be muted.
   *
   * @method #forceMuteStream
   * @memberOf Session
   * @see <a href="Session.html#properties">Session.capabilities</a>
   * @see <a href="Session.html#forceMuteAll">Session.forceMuteAll()</a>
   * @see <a href="Publisher.html#event:muteForced">Publisher muteForced event</a>
   * @see <a href="https://tokbox.com/developer/guides/moderation/js/#force_mute">Muting the audio
   * of streams in a session</a>
   *
   * @return {Promise} A promise that resolves with no value when the operation
   * completes successfully. The promise is rejected if there is an error. The
   * <code>name</code> property of the Error object is set to one of the following
   * values, depending the type of error:
   *
   * <p>
   * <ul>
   *
   *   <li><code>'OT_NOT_CONNECTED'</code> &mdash; The client is not connect to the session.</li>
   *
   *   <li><code>'OT_INVALID_PARAMETER'</code> &mdash; if one or more of the passed parameters are invalid.</li>
   *
   *   <li><code>'OT_PERMISSION_DENIED'</code> &mdash; The user's role does not
   *   include permissions required to force other users to mute.
   *   You define a user's role when you create the user token (see the
   *   <a href="https://tokbox.com/developer/guides/create-token/">Token creation overview</a>).
   *   You pass the token string as a parameter of the <code>connect()</code> method of the Session
   *   object.
   *   </li>
   *
   *   <li><code>'OT_NOT_FOUND'</code> &mdash; The stream wasn't found in this session.</li>
   *
   *   <li><code>'OT_UNEXPECTED_SERVER_RESPONSE'</code> &mdash; In case of an internal server error.</li>
   *
   * <ul>
   * </p>
   */

    this.forceMuteStream = stream => new Promise((resolve, reject) => {
      const error = checkMuteCapabilities();
      if (error) {
        reject(error);
        return;
      }

      if (!stream || !stream.id) {
        reject(dispatchMuteError(forceMuteErrors.INVALID_PARAMETER));
        return;
      }

      _socket.forceMuteStream(stream.id, (err) => {
        if (err) {
          reject(handleMuteServerError(err));
        } else {
          resolve();
        }
      });
    });

    /**
     * Forces all publishers in the session (except for those publishing excluded streams)
     * to mute audio.
     *
     * <p>
     * A stream published by the moderator calling the <code>forceMuteAll()</code> method is muted
     * along with other streams in the session, unless you add the moderator&apos;s stream (or streams) to
     * the excluded streams array.
     * </p>
     *
     * <p>
     * If you leave out the <code>excludedStreams</code> parameter, all streams in the session
     * (including those of the moderator) will stop publishing audio:
     * </p>
     *
     * <pre><code class="js">session.forceMuteAll();
     * </code></pre>
     *
     * <p>
     * Also, any streams that are published after the call to the <code>forceMuteAll()</code> method
     * are published with audio muted. You can remove the mute state of a session by calling the
     * <code>disableForceMute()</code> method of the Session object.
     * </p>
     *
     * <pre><code class="js">session.disableForceMute();
     * </code></pre>
     *
     * <p>After you call the <code>Session.disableForceMute()</code> method, new streams published
     * to the session will no longer have audio muted.</p>
     *
     * <p>
     * Calling this method causes the Publisher objects in the clients publishing
     * the streams to dispatch <code>muteForced</code> events. Also, the Session object
     * in each client connected to the session dispatches the <code>muteForced</code>
     * event (with the <code>active</code> property of the event object set to <code>true</code>).
     * </p>
     *
     * <p>
     * Check the <code>capabilities.canForceMute</code> property of the Session object to see if
     * you can call this function successfully. This is reserved for clients that have connected
     * with a token that has been assigned the moderator role (see the
     * <a href="https://tokbox.com/developer/guides/create-token/">Token Creation</a>
     * documentation).
     *
     * @param {Array} excludedStreams An array of Stream objects to be excluded from
     * being muted. Note that if you want to prevent the local client's published stream(s)
     * from being muted, include the Stream object(s) for those stream(s) in this array.
     *
     * @method #forceMuteAll
     * @memberOf Session
     *
     * @see <a href="Session.html#properties">Session.capabilities</a>
     * @see <a href="Session.html#forceMuteStream">Session.forceMuteStream()</a>
     * @see <a href="Session.html#disableForceMute">Session.disableForceMute()</a>
     * @see <a href="#event:muteForced">Session muteForced event</a>
     * @see <a href="Publisher.html#event:muteForced">Publisher muteForced event</a>
     * @see <a href="https://tokbox.com/developer/guides/moderation/js/#force_mute">Muting the audio
     * of streams in a session</a>
     *
     * @return {Promise} A promise that resolves with no value when the operation
     * completes successfully. The promise is rejected if there is an error. The
     * <code>name</code> property of the Error object is set to one of the following
     * values, depending the type of error:
     *
     * <p>
     * <ul>
     *
     *   <li><code>'OT_NOT_CONNECTED'</code> &mdash; The client is not connect to the session.</li>
     *
     *   <li><code>'INVALID_PARAMETER'</code> &mdash; if one or more of the passed parameters are invalid.</li>
     *
     *   <li><code>'OT_PERMISSION_DENIED'</code> &mdash; The user's role does not
     *   include permissions required to force other users to mute.
     *   You define a user's role when you create the user token (see the
     *   <a href="https://tokbox.com/developer/guides/create-token/">Token creation overview</a>).
     *   You pass the token string as a parameter of the <code>connect()</code> method of the Session
     *   object.
     *   </li>
     *
     *   <li><code>'OT_UNEXPECTED_SERVER_RESPONSE'</code> &mdash; in case of an internal server error.</li>
     * <ul>
     * </p>
     */

    this.forceMuteAll = excludedStreams => new Promise((resolve, reject) => {
      const error = checkMuteCapabilities();
      if (error) {
        reject(error);
        return;
      }

      // Invalid parameter error should occur when an array of elements is passed where 1 or more of these elements is not
      // an instanceof OT.Stream. excludedStreams can be undefined, null or an empty array and this just means all streams
      // in the session need to be muted
      if ((Array.isArray(excludedStreams) && !excludedStreams.every(stream => stream instanceof Stream))
        || (excludedStreams && !Array.isArray(excludedStreams))) {
        reject(dispatchMuteError(forceMuteErrors.INVALID_PARAMETER));
        return;
      }

      const excludedStreamIds = (excludedStreams || []).map(stream => stream.id);

      _socket.forceMuteAll(excludedStreamIds, true, (err) => {
        if (err) {
          reject(handleMuteServerError(err));
        } else {
          resolve();
        }
      });
    });

    /**
     * Disables the active mute state of the session. After you call this method, new streams
     * published to the session will no longer have audio muted.
     *
     * <p>
     * After you call to the <code>Session.forceMuteAll()</code> method (or a moderator in another
     * client makes a call to mute all streams), any streams published after the moderation call are
     * published with audio muted. Call the <code>disableForceMute()</code> method to remove
     * the mute state of a session (so that new published streams are not automatically muted).
     * </p>
     *
     * <p>
     * Calling this method causes the Session object in each connected clients to dispatch a
     * <code>muteForced</code> event, with the <code>active</code> flag set to <code>false</code>.
     * </p>
     *
     * <p>
     * Check the <code>capabilities.canForceMute</code> property of the Session object to see if
     * you can call this function successfully. This is reserved for clients that have connected
     * with a token that has been assigned the moderator role (see the
     * <a href="https://tokbox.com/developer/guides/create-token/">Token Creation</a>
     * documentation).
     *
     * @method #disableForceMute
     * @memberOf Session
     *
     * @see <a href="Session.html#properties">Session.capabilities</a>
     * @see <a href="Session.html#forceMuteAll">Session.forceMuteAll()</a>
     * @see <a href="#event:muteForced">muteForced event</a>
     *
     * @return {Promise} A promise that resolves with no value when the operation
     * completes successfully. The promise is rejected if there is an error. The
     * <code>name</code> property of the Error object is set to one of the following
     * values, depending the type of error:
     *
     * <p>
     * <ul>
     *
     *   <li><code>'OT_NOT_CONNECTED'</code> &mdash; The client is not connect to the session.</li>
     *
     *   <li><code>'OT_PERMISSION_DENIED'</code> &mdash; The user's role does not
     *   include permissions required to force other users to mute.
     *   You define a user's role when you create the user token (see the
     *   <a href="https://tokbox.com/developer/guides/create-token/">Token creation overview</a>).
     *   You pass the token string as a parameter of the <code>connect()</code> method of the Session
     *   object.
     *   </li>
     *
     *   <li><code>'OT_UNEXPECTED_SERVER_RESPONSE'</code> &mdash; In case of an internal server error.</li>
     * <ul>
     * </p>
     */

    this.disableForceMute = () => new Promise((resolve, reject) => {
      const error = checkMuteCapabilities();
      if (error) {
        reject(error);
        return;
      }

      _socket.forceMuteAll([], false, (err) => {
        if (err) {
          reject(handleMuteServerError(err));
        } else {
          resolve();
        }
      });
    });

    /**
     * Sets the encryption secret for a session with  end-to-end encryption enabled. If this method is called in a session without
     * encryption enabled the function will resolve and no error will be thrown. Users may change their set encryption key at
     * any time. See the end-to-end encryption <a href="https://tokbox.com/developer/guides/end-to-end-encryption/">
     * developer guide</a>.
     *
     * @method #setEncryptionSecret
     * @memberOf Session
     * @param {string} secret The encryption secret.
     *
     * @see <a href="OT.html#initSession">OT.initSession</a>
     * @see <a href="Subscriber.html#events">Subscriber events</a>
     *
     * @return {Promise} A promise that resolves with no value when the operation
     * completes successfully. The promise is rejected if there is an error. The
     * <code>name</code> property of the Error object is set to one of the following
     * values, depending the type of error:
     *
     * <p>
     * <ul>
     * <li><code>'OT_INVALID_ENCRYPTION_SECRET'</code>; The secret is invalid.</li>
     * <ul>
     * </p>
     *
     * A secret mismatch may occur when no error is dispatched. See
     * <a href="Subscriber.html#events">subscrriber events</a> for more information.
     */

    this.setEncryptionSecret = async (secret) => {
      if (!encryptionSecret) {
        logging.error('Encryption secret must first be set in initSession.');
        return;
      }
      try {
        validateSecret(secret);
      } catch (e) {
        throw otError(
          errors.INVALID_ENCRYPTION_SECRET,
          new Error(`setEncryptionSecret: ${e.message}`)
        );
      }
      try {
        await this.keyStore.set(sessionId, secret);
        encryptionSecret = secret;
      } catch (e) {
        _logging.error(`Error in setEncryptionSecret}: ${e.message}`);
      }
    };

    this.isConnected = () => this.is('connected');
    this.capabilities = new Capabilities([], { hasE2eeCapability });
  };

  /**
   * Dispatched when an archive recording of the session starts.
   *
   * @name archiveStarted
   * @event
   * @memberof Session
   * @see ArchiveEvent
   * @see <a href="https://tokbox.com/developer/guides/archiving">Archiving overview</a>
   */

  /**
   * Dispatched when an archive recording of the session stops.
   *
   * @name archiveStopped
   * @event
   * @memberof Session
   * @see ArchiveEvent
   * @see <a href="https://tokbox.com/developer/guides/archiving">Archiving overview</a>
   */

  /**
   * Dispatched when a new client (including your own) has connected to the session, and for
   * every client in the session when you first connect. (The Session object also dispatches
   * a <code>sessionConnected</code> event when your local client connects.)
   *
   * @name connectionCreated
   * @event
   * @memberof Session
   * @see ConnectionEvent
   * @see <a href="OT.html#initSession">OT.initSession()</a>
   */

  /**
   * A client, other than your own, has disconnected from the session.
   * @name connectionDestroyed
   * @event
   * @memberof Session
   * @see ConnectionEvent
   */

  /**
   * A moderator has forced clients publishing streams to the session to mute audio (the
   * <code>active</code> property of this MuteForcedEvent object is set to <code>true</code>), or
   * a moderator has disabled the mute audio state in the session (the <code>active</code>
   * property of this MuteForcedEvent object is set to <code>false</code>).
   *
   * @name muteForced
   * @event
   * @memberof Session
   * @see MuteForcedEvent
   * @see <a href="Session.html#forceMuteAll">Session.forceMuteAll()</a>
   * @see <a href="Session.html#disableForceMute">Session.disableForceMute()</a>
   * @see <a href="https://tokbox.com/developer/guides/moderation/js/#force_mute">Muting the audio
   * of streams in a session</a>
   */

  /**
   * The CPU performance state has changed. This can be set to
   * one the following: "nominal", "fair", "serious", or "critical".
   *
   * @name cpuPerformanceChanged
   * @event
   * @memberof Session
   * @see CpuPerformanceChangedEvent
   */

  /**
   * The client has connected to a Vonage Video API session. This event is dispatched asynchronously
   * in response to a successful call to the <code>connect()</code> method of a Session
   * object. Before calling the <code>connect()</code> method, initialize the session by
   * calling the <code>OT.initSession()</code> method. For a code example and more details,
   * see <a href="#connect">Session.connect()</a>.
   * @name sessionConnected
   * @event
   * @memberof Session
   * @see SessionConnectEvent
   * @see <a href="#connect">Session.connect()</a>
   * @see <a href="OT.html#initSession">OT.initSession()</a>
   */

  /**
   * The client has disconnected from the session. This event may be dispatched asynchronously
   * in response to a successful call to the <code>disconnect()</code> method of the Session object.
   * The event may also be dispatched if a session connection is lost inadvertently, as in the case
   * of a lost network connection.
   * <p>
   * The default behavior is that all Subscriber objects are unsubscribed and removed from the
   * HTML DOM. Each Subscriber object dispatches a <code>destroyed</code> event when the element is
   * removed from the HTML DOM. If you call the <code>preventDefault()</code> method in the event
   * listener for the <code>sessionDisconnect</code> event, the default behavior is prevented, and
   * you can, optionally, clean up Subscriber objects using your own code.
   * <p> The <code>reason</code> property of the event object indicates the reason for the client
   * being disconnected.
   * @name sessionDisconnected
   * @event
   * @memberof Session
   * @see <a href="#disconnect">Session.disconnect()</a>
   * @see <a href="#forceDisconnect">Session.forceDisconnect()</a>
   * @see SessionDisconnectEvent
   */

  /**
   * The local client has lost its connection to a Vonage Video API session and is trying to reconnect.
   * This results from a loss in network connectivity. If the client can reconnect to the session,
   * the Session object dispatches a <code>sessionReconnected</code> event. Otherwise, if the client
   * cannot reconnect, the Session object dispatches a <code>sessionDisconnected</code> event.
   * <p>
   * In response to this event, you may want to provide a user interface notification, to let
   * the user know that the app is trying to reconnect to the session and that audio-video streams
   * are temporarily disconnected.
   *
   * @name sessionReconnecting
   * @event
   * @memberof Session
   * @see Event
   * @see <a href="#event:sessionReconnected">sessionReconnected event</a>
   * @see <a href="#event:sessionDisconnected">sessionDisconnected event</a>
   */

  /**
   * The local client has reconnected to the Vonage Video API session after its connection was lost
   * temporarily. When the connection is lost, the Session object dispatches a
   * <code>sessionReconnecting</code> event, prior to the <code>sessionReconnected</code>
   * event. If the client cannot reconnect to the session, the Session object dispatches a
   * <code>sessionDisconnected</code> event instead of this event.
   * <p>
   * Any existing publishers and subscribers are automatically reconnected when client reconnects
   * and the Session object dispatches this event.
   * <p>
   * Any signals sent by other clients while your client was disconnected are received upon
   * reconnecting. By default, signals initiated by the local client while disconnected
   * (by calling the <code>Session.signal()</code> method) are sent when the client reconnects
   * to the Vonage Video API session. You can prevent this by setting the <code>retryAfterReconnect</code>
   * property to <code>false</code> in the <code>signal</code> object you pass into the
   * <a href="#signal">Session.signal()</a> method.
   *
   * @name sessionReconnected
   * @event
   * @memberof Session
   * @see Event
   * @see <a href="#event:sessionReconnecting">sessionReconnecting event</a>
   * @see <a href="#event:sessionDisconnected">sessionDisconnected event</a>
   */

  /**
   * A new stream, published by another client, has been created on this session. For streams
   * published by your own client, the Publisher object dispatches a <code>streamCreated</code>
   * event. For a code example and more details, see {@link StreamEvent}.
   * @name streamCreated
   * @event
   * @memberof Session
   * @see StreamEvent
   * @see <a href="Session.html#publish">Session.publish()</a>
   */

  /**
   * A stream from another client has stopped publishing to the session.
   * <p>
   * The default behavior is that all Subscriber objects that are subscribed to the stream are
   * unsubscribed and removed from the HTML DOM. Each Subscriber object dispatches a
   * <code>destroyed</code> event when the element is removed from the HTML DOM. If you call the
   * <code>preventDefault()</code> method in the event listener for the
   * <code>streamDestroyed</code> event, the default behavior is prevented and you can clean up
   * a Subscriber object for the stream by calling its <code>destroy()</code> method. See
   * <a href="Session.html#getSubscribersForStream">Session.getSubscribersForStream()</a>.
   * <p>
   * For streams published by your own client, the Publisher object dispatches a
   * <code>streamDestroyed</code> event.
   * <p>
   * For a code example and more details, see {@link StreamEvent}.
   * @name streamDestroyed
   * @event
   * @memberof Session
   * @see StreamEvent
   */

  /**
   * Defines an event dispatched when property of a stream has changed. This can happen in
   * in the following conditions:
   * <p>
   * <ul>
   *   <li> A stream has started or stopped publishing audio or video (see
   *     <a href="Publisher.html#publishAudio">Publisher.publishAudio()</a> and
   *     <a href="Publisher.html#publishVideo">Publisher.publishVideo()</a>). Note
   *     that a subscriber's video can be disabled or enabled for reasons other than
   *     the publisher disabling or enabling it. A Subscriber object dispatches
   *     <code>videoDisabled</code> and <code>videoEnabled</code> events in all
   *     conditions that cause the subscriber's stream to be disabled or enabled.
   *   </li>
   *   <li> The <code>videoDimensions</code> property of the Stream object has
   *     changed (see <a href="Stream.html#properties">Stream.videoDimensions</a>).
   *   </li>
   *   <li> The <code>videoType</code> property of the Stream object has changed.
   *     This can happen in a stream published by a mobile device. (See
   *     <a href="Stream.html#properties">Stream.videoType</a>.)
   *   </li>
   * </ul>
   *
   * @name streamPropertyChanged
   * @event
   * @memberof Session
   * @see StreamPropertyChangedEvent
   * @see <a href="Publisher.html#publishAudio">Publisher.publishAudio()</a>
   * @see <a href="Publisher.html#publishVideo">Publisher.publishVideo()</a>
   * @see <a href="Stream.html#hasAudio">Stream.hasAudio</a>
   * @see <a href="Stream.html#hasVideo">Stream.hasVideo</a>
   * @see <a href="Stream.html#videoDimensions">Stream.videoDimensions</a>
   * @see <a href="Subscriber.html#event:videoDisabled">Subscriber videoDisabled event</a>
   * @see <a href="Subscriber.html#event:videoEnabled">Subscriber videoEnabled event</a>
   */

  /**
   * A signal was received from the session. The <a href="SignalEvent.html">SignalEvent</a>
   * class defines this event object. It includes the following properties:
   * <ul>
   *   <li><code>data</code> &mdash; (String) The data string sent with the signal (if there
   *       is one).</li>
   *   <li><code>from</code> &mdash; (<a href="Connection.html">Connection</a>) The Connection
   *       corresponding to the client that sent the signal.</li>
   *   <li><code>type</code> &mdash; (String) The type assigned to the signal (if there is
   *       one).</li>
   * </ul>
   * <p>
   * You can register to receive all signals sent in the session, by adding an event handler
   * for the <code>signal</code> event. For example, the following code adds an event handler
   * to process all signals sent in the session:
   * <pre>
   * session.on("signal", function(event) {
   *   console.log("Signal sent from connection: " + event.from.id);
   *   console.log("Signal data: " + event.data);
   * });
   * </pre>
   * <p>You can register for signals of a specified type by adding an event handler for the
   * <code>signal:type</code> event (replacing <code>type</code> with the actual type string
   * to filter on).
   *
   * @name signal
   * @event
   * @memberof Session
   * @see <a href="Session.html#signal">Session.signal()</a>
   * @see SignalEvent
   * @see <a href="#event:signal:type">signal:type</a> event
   */

  /**
   * A signal of the specified type was received from the session. The
   * <a href="SignalEvent.html">SignalEvent</a> class defines this event object.
   * It includes the following properties:
   * <ul>
   *   <li><code>data</code> &mdash; (String) The data string sent with the signal.</li>
   *   <li><code>from</code> &mdash; (<a href="Connection.html">Connection</a>) The Connection
   *   corresponding to the client that sent the signal.</li>
   *   <li><code>type</code> &mdash; (String) The type assigned to the signal (if there is one).
   *   </li>
   * </ul>
   * <p>
   * You can register for signals of a specified type by adding an event handler for the
   * <code>signal:type</code> event (replacing <code>type</code> with the actual type string
   * to filter on). For example, the following code adds an event handler for signals of
   * type "foo":
   * <pre>
   * session.on("signal:foo", function(event) {
   *   console.log("foo signal sent from connection " + event.from.id);
   *   console.log("Signal data: " + event.data);
   * });
   * </pre>
   * <p>
   * You can register to receive <i>all</i> signals sent in the session, by adding an event
   * handler for the <code>signal</code> event.
   *
   * @name signal:type
   * @event
   * @memberof Session
   * @see <a href="Session.html#signal">Session.signal()</a>
   * @see SignalEvent
   * @see <a href="#event:signal">signal</a> event
   */

  return Session;
}
